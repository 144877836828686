import React, { useState } from "react";
import Content from "../../components/shared/Content";
import { Icon } from "@iconify/react";
import { useForm } from "react-hook-form";
import SettingsInputField from "../../components/shared/SettingsInputField";

const tabs = ["Account", "Settings"];

function Settings() {
  const [active, setActive] = useState("Account");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submit = (data) => {};

  return (
    <div className="p-4 cursor-pointer">
      <div className="bg-white pt-1 pb-2 mt-2 rounded-xl h-[85vh] px-6">
        <div className=" mt-10 ">
          <ul class="hidden text-sm font-semibold  text-center text-gray-500 sm:flex">
            {tabs.map((tab, index) => (
              <li
                key={index}
                className={`p-3 w-28 rounded-md justify-center items-center focus:ring-2 focus:ring-gray-400 active focus:outline-none cursor-pointer duration-500 font-kas text-md ${
                  tab === active ? "text-[#008080] " : "text-zinc-600"
                }`}
                onClick={() => setActive(tab)}
              >
                {tab}
                {tab === active && (
                  <div className="border-[0.5px] mt-1 border-[#008080]"></div>
                )}
              </li>
            ))}
          </ul>
        </div>

        <Content id={"Account"} active={active}>
          <div className="mb-2">
            <div className="flex justify-between items-center">
              <div className="text-zinc-900 text-md font-medium font-kas my-4 ml-5">
                Account Settings
              </div>
              <div className="flex justify-center items-center bg-[#008080] mr-5 py-2 rounded-xl px-9">
                <div className="text-center text-white text-md font-medium font-new leading-normal flex">
                  Update
                </div>
              </div>
            </div>
          </div>

          <form onSubmit={handleSubmit(submit)}>
            <div className="grid grid-cols-3">
              <div className="pl-5 space-y-5">
                <div className="">
                  <SettingsInputField
                    name="First Name"
                    icon="solar:user-linear"
                    label="First Name"
                    placeholder="Akuah"
                    errors={errors}
                    register={register}
                    required
                  />
                </div>
                <div className="">
                  <SettingsInputField
                    name="Last Name"
                    icon="solar:user-linear"
                    label="Last Name"
                    placeholder="Kwojo"
                    errors={errors}
                    register={register}
                    required
                  />
                </div>
                <div className="">
                  <SettingsInputField
                    name="Email"
                    icon="mdi-light:email"
                    label="Email"
                    placeholder="akuahkwojo@gmail.com"
                    errors={errors}
                    register={register}
                    required
                  />
                </div>
                <div className="">
                  <SettingsInputField
                    name="Phone Number"
                    icon="bi:phone"
                    label="Phone Number"
                    placeholder="0248784679"
                    errors={errors}
                    register={register}
                    required
                  />
                </div>
                <div className="">
                  <SettingsInputField
                    name="Address"
                    icon="system-uicons:location"
                    label="Address"
                    placeholder="No. 93 Skyfield Apartments"
                    errors={errors}
                    register={register}
                    required
                  />
                </div>
                <div className="flex gap-2">
                  <div className="">
                    <SettingsInputField
                      name="Country"
                      icon="system-uicons:location"
                      label="Country"
                      placeholder="Ghana"
                      errors={errors}
                      register={register}
                      required
                    />
                  </div>
                  <div className="">
                    <SettingsInputField
                      name="State"
                      icon="system-uicons:location"
                      label="State"
                      placeholder="Accra"
                      errors={errors}
                      register={register}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className=" ml-10">
                <div className="relative">
                  <img
                    src="/images/employee.jpg"
                    alt=""
                    className="h-48 w-56 object-cover bg-center rounded-xl"
                  />
                  <div className="absolute top-0 ml-32 mt-2 flex items-center justify-center">
                    <div className="h-9 w-9 bg-[#9EE1FD] flex items-center justify-center rounded-lg mr-2">
                      <Icon
                        icon="pepicons-pencil:cloud-up"
                        className="text-black text-2xl cursor-pointer mx-2 h-8 w-8"
                      />
                    </div>

                    <div className="h-9 w-9 bg-[#9EE1FD] flex items-center justify-center rounded-lg">
                      <Icon
                        icon="mingcute:delete-line"
                        className="text-black text-2xl cursor-pointer mx-2 h-5 w-5"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Content>

        <Content id={"Settings"} active={active}>
          <div className="">
            <div className="flex justify-between items-center">
              <div className="text-zinc-900 text-md font-medium font-kas my-4 ml-5">
                Business Settings
              </div>
              <div className="flex justify-center items-center bg-[#008080] mr-5 py-2 rounded-xl px-9">
                <div className="text-center text-white text-md font-medium font-new leading-normal flex">
                  Update
                </div>
              </div>
            </div>
          </div>
          <form>
            <div className="grid grid-cols-3">
              <div className="pl-5 space-y-5">
                <div className="">
                  <SettingsInputField
                    name="Business Name"
                    icon="solar:bag-4-outline"
                    label="Business Name"
                    placeholder="Akuah Cosmetics Ventures"
                    errors={errors}
                    register={register}
                    required
                  />
                </div>
                <div className="">
                  <SettingsInputField
                    name="Business Email"
                    icon="mdi-light:email"
                    label="Business Email"
                    placeholder="akuahcosmetics@gmail.com"
                    errors={errors}
                    register={register}
                    required
                  />
                </div>

                <div className="">
                  <SettingsInputField
                    name="Business Number"
                    icon="bi:phone"
                    label="Business Number"
                    placeholder="0248784679"
                    errors={errors}
                    register={register}
                    required
                  />
                </div>
                <div className="">
                  <SettingsInputField
                    name="Address"
                    icon="system-uicons:location"
                    label="Address"
                    placeholder="No. 93 Skyfield Nyanyo"
                    errors={errors}
                    register={register}
                    required
                  />
                </div>
                <div className="flex gap-2">
                  <div className="">
                    <SettingsInputField
                      name="Country"
                      icon="system-uicons:location"
                      label="Country"
                      placeholder="Ghana"
                      errors={errors}
                      register={register}
                      required
                    />
                  </div>
                  <div className="">
                    <SettingsInputField
                      name="State"
                      icon="system-uicons:location"
                      label="State"
                      placeholder="Accra"
                      errors={errors}
                      register={register}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className=" ml-10">
                <div className="relative">
                  <img
                    src="/images/d2.jpg"
                    alt=""
                    className="h-48 w-56 object-cover bg-center rounded-xl"
                  />
                  <div className="absolute top-0 ml-32 mt-2 flex items-center justify-center">
                    <div className="h-9 w-9 bg-[#9EE1FD] flex items-center justify-center rounded-lg mr-2">
                      <Icon
                        icon="pepicons-pencil:cloud-up"
                        className="text-black text-2xl cursor-pointer mx-2 h-8 w-8"
                      />
                    </div>

                    <div className="h-9 w-9 bg-[#9EE1FD] flex items-center justify-center rounded-lg">
                      <Icon
                        icon="mingcute:delete-line"
                        className="text-black text-2xl cursor-pointer mx-2 h-5 w-5"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Content>
      </div>
    </div>
  );
}

export default Settings;

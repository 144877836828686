import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useMutate } from "../../hooks/useMutate";
import useUserStore from "../../store/useUserStore";
import Spinner1 from "../../components/shared/Spinner1";

function SignIn() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [type, setType] = useState("password");

  const setUser = useUserStore((state) => state.setUser);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      phone_number: "",
      password: "",
    },
  });

  const handleToggle = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  const navigate = useNavigate();
  const { mutate } = useMutate(["login"]);
  const submit = (data) => {
    setIsSubmitting(true);
    mutate(
      {
        url: "/login",
        data,
      },
      {
        onSuccess(data) {
          setUser(data.message);
          setIsSubmitting(false);
          navigate("/");
        },
        onError(error) {
          console.log({ error });
          toast.error(error.response.data.message.message);
          setIsSubmitting(false);
        },
      }
    );
  };

  return (
    <div className="flex justify-center items-center h-screen bg-neutral-50 cursor-pointer">
      <Spinner1 isSubmitting={isSubmitting} />
      <div className=" w-[30%] shadow-md rounded-lg px-10 py-7">
        <div className="">
          <div className="flex justify-center">
            <img src="/images/logo.png" alt="" />
          </div>
          <div className="flex justify-center mt-3">
            <div className="text-zinc-900 text-2xl font-semibold font-kas leading-loose">
              Welcome to Kasoa App
            </div>
          </div>
          <div className=" text-center text-neutral-500 text-base font-normal font-kas leading-relaxed flex justify-center mb-3">
            Please log in to see your data
          </div>
        </div>
        <form onSubmit={handleSubmit(submit)} className="">
          <div className="mt-5">
            <div className="flex items-center bg-neutral-100 w-full outline-none border-gray-200 rounded-xl mt-6">
              <div className="pl-3">
                <Icon
                  icon="material-symbols-light:numbers"
                  className="mr-1 h-6 w-6"
                />
              </div>
              <input
                type="text"
                placeholder="Enter Phone Number"
                className=" placeholder:text-zinc-700 placeholder:text-base placeholder:font-kas placeholder:font-normal block bg-neutral-100 w-full outline-none border-gray-200 rounded-xl py-3 pl-2 pr-3 sm:text-sm h-12"
                {...register("phone_number", {
                  required: {
                    value: true,
                    message: "Please Enter Phone Number",
                  },
                })}
              />
            </div>
            {errors["phone_number"] && (
              <span className="text-red-500 text-[12px] font-kas">
                {errors["phone_number"].message}
              </span>
            )}

            <div className="flex items-center bg-neutral-100 w-full outline-none border-gray-200 rounded-xl mt-6">
              <div className="pl-3">
                <Icon icon="solar:lock-broken" className="mr-1 h-5 w-5" />
              </div>
              <input
                type={type}
                placeholder="Password"
                className=" placeholder:text-zinc-700 placeholder:text-base placeholder:font-kas placeholder:font-normal block bg-neutral-100 w-full outline-none border-gray-200 rounded-xl py-3 pl-2 pr-3 sm:text-sm h-12"
                {...register("password", {
                  required: {
                    value: true,
                    message: "Please Enter Password",
                  },
                })}
              />

              <div onClick={handleToggle} className="cursor-pointer pr-3">
                {type === "password" ? (
                  <Icon
                    icon="fluent:eye-off-20-regular"
                    className="mr-1 h-6 w-6"
                  />
                ) : (
                  <Icon icon="fluent:eye-32-regular" className="mr-1 h-6 w-6" />
                )}
              </div>
            </div>

            {errors["password"] && (
              <span className="text-red-500 text-[12px] font-kas">
                {errors["password"].message}
              </span>
            )}
          </div>

          <div className="flex justify-center mt-10">
            <button className="bg-primary px-10 py-2 rounded-md text-center text-white font-kas text-sm font-medium font-new leading-normal">
              Sign In
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignIn;

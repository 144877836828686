import React from "react";

const Box = ({ label, value }) => {
  return (
    <div className="bg-white rounded-lg border p-4 w-full">
      <h6 className="text-neutral-500 text-md  font-medium font-kas leading-tight mb-3">
        {label}
      </h6>
      <p className="text-zinc-900 text-sm font-bold font-kas leading-tight">
        {value}
      </p>
    </div>
  );
};

export default Box;

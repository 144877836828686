import { Icon } from "@iconify/react";
import React from "react";
import Box from "../../components/shared/Box";

function BusinessDetails({ business, closeDetails, handleDelete }) {
  // const { data } = useGetQuery({
  //   url: `/member/${business?._id}`,
  //   queryKey: ["member", business?._id],
  //   enabled: !!business,
  // });

  return (
    <div className="p-4 cursor-pointer w-full">
      <div className="min-h-[17rem] bg-white rounded-lg shadow-md relative">
        <div className="h-40 bg-primary rounded-lg">
          <div className="flex justify-end text-white text-2xl font-semibold font-kas leading-tight">
            <div className="mt-16 mr-10">Business Details</div>
          </div>
        </div>

        <div className="relative pb-3 left-0 w-11/12 mx-auto object-cover rounded-t-lg flex items-center">
          <div className="ml-3 w-full pt-2">
            <div className="text-zinc-900 text-xl font-bold font-kas leading-normal">
              {business?.business_name}
            </div>
            <div className="text-zinc-500 text-sm font-medium font-kas leading-none mt-1">
              {business?.business_email}
            </div>
            <div className="grid grid-auto-fit-sm">
              <div className="flex items-center mt-3 w-full">
                <Icon icon="bi:phone" className="h-5 w-5 mr-1" />
                <div className="text-neutral-500 text-sm font-medium font-kas leading-none">
                  {business?.business_phone_number}
                </div>
              </div>
              <div className="flex items-center mt-3 w-full">
                <Icon icon="ci:users-group" className="h-5 w-5 mr-2" />
                <div className="text-neutral-500 text-sm font-medium font-kas leading-none">
                  {business?.group?.name}
                </div>
              </div>
              <div className="flex items-center mt-3">
                <div>
                  <Icon
                    icon="system-uicons:location"
                    className="h-5 w-5 mr-1"
                  />
                </div>
                <div className="text-neutral-500 text-sm font-medium font-kas leading-none">
                  {business?.business_city}, {""}
                  {business?.business_country}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" bg-white shadow-lg mt-6 rounded-lg p-6">
        <div className="text-zinc-900 text-lg font-bold font-['Poppins'] leading-tight mb-5">
          Transaction Details
        </div>
        <div className="grid gap-5 grid-auto-fit-sm">
          <Box label="Months Paid" value={business?.paid_for_date.length} />
          <Box
            label="Months Owing"
            value={business?.all_dates_to_be_paid_for.length}
          />
          <Box label="Current Month" value={business?.current_month} />
          <Box
            label="Paid for current month"
            value={business?.has_paid_for_current_month ? "Yes" : "Not Yet"}
          />
          <Box
            label="Paid for Previous month"
            value={business?.has_paid_for_previous_month ? "Yes" : "No"}
          />
          <Box label="Date Created" value={business?.date_created} />
        </div>
      </div>
    </div>
  );
}

export default BusinessDetails;

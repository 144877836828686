import React, { useState } from "react";
import { Icon } from "@iconify/react";
import Modal from "../../components/shared/Modal";
import RoleForm from "../../components/forms/RoleForm";
import { useGetQuery } from "../../hooks/useGetQuery";
import Spinner from "../../components/shared/Spinner";
import Table from "../../components/shared/Table";
import { QueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import useConfirm from "../../hooks/useConfirm";
import { useMutate } from "../../hooks/useMutate";
import { useQueryClient } from "@tanstack/react-query";

function Role() {
  const queryClient = useQueryClient();
  const [role, setRole] = useState(null);
  const { ConfirmationDialog, confirm, setIsOpen } = useConfirm();
  const [OpenRoleForm, setOpenRoleForm] = useState(false);

  const { data, isLoading } = useGetQuery({
    url: "/roles",
    queryKey: ["roles"],
  });

  const handleEdit = (role) => {
    setRole(role);
    setOpenRoleForm(true);
  };

  const { mutate } = useMutate({ queryKey: ["delete-role"] });
  const handleDelete = async (role) => {
    const isConfirmed = await confirm({
      title: "Are You Sure?",
      message: `Are you sure you want to delete "${role?.name}" role?`,
    });

    if (isConfirmed) {
      const toastId = toast.loading(`Deleting ${role?.name} role...`);
      mutate(
        {
          url: `/role/${role?._id}`,
          method: "DELETE",
        },
        {
          onSuccess() {
            queryClient.setQueryData(["roles"], (oldData) =>
              (oldData || []).filter((item) => item._id !== role?._id)
            );
            toast.dismiss(toastId);
            toast.success(`${role?.name} deleted successfully`);
            setIsOpen(false);
          },
          onError(error) {
            toast.dismiss(toastId);
            toast.error(error.response.data.message);
          },
        }
      );
    }
  };

  const columns = [
    {
      id: "SN",
      header: "SN",
      accessorKey: "",
      cell: (info) => <span className="px-1">{info.row.index + 1}</span>,
    },
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Permissions",
      accessorKey: "permissions",
      cell: (cell) =>
        cell
          .getValue()
          .map(
            (value, index) =>
              `${value}${cell.getValue().length !== index + 1 ? ", " : ""}`
          ),
    },
    {
      header: "Actions",
      cell: (info) => (
        <div className="w-[%] flex gap-5">
          <Icon
            onClick={() => handleEdit(info.row.original)}
            icon="iconamoon:edit-light"
            className="text-xl"
          />
          <Icon
            onClick={() => handleDelete(info.row.original)}
            icon="fluent:delete-48-regular"
            className="text-xl text-red-700"
          />
        </div>
      ),
    },
  ];

  return (
    <div className="px-5 cursor-pointer">
      <Spinner isSubmitting={isLoading} />

      <div className="flex justify-between items-center mt-4">
        <div className="text-zinc-900 text-lg font-medium font-kas my-4">
          Roles table
        </div>
        <div
          onClick={() => setOpenRoleForm(true)}
          className="flex justify-center items-center bg-[#008080] mr- py-3 rounded-lg px-3"
        >
          <div className="text-center text-white text-md font-medium font-new leading-normal flex">
            <Icon icon="iconoir:plus" className="text-white mr-1 h-6 w-6" />
            Add Role
          </div>
        </div>
      </div>

      <Table data={data ?? []} columns={columns} />
      <Modal openModal={OpenRoleForm} closeModal={setOpenRoleForm}>
        <RoleForm role={role} setOpenModal={setOpenRoleForm} />
      </Modal>

      <ConfirmationDialog />
    </div>
  );
}

export default Role;

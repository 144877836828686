import React, { useState } from "react";
import { Icon } from "@iconify/react";
import AnnouncementForm from "../../components/forms/AnnouncementForm";
import Modal from "../../components/shared/Modal";

const announcements = [
  {
    title: "Announcement name goes here",
    name: "Hi Anastassia!",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor",
    date: "Nov 01, 2023",
  },
  {
    title: "Announcement name goes here",
    name: "Meeting Agenda!",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor",
    date: "Oct 18, 2023",
  },
  {
    title: "Announcement name goes here",
    name: "Today Meeting Schedule",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor",
    date: "Oct 22, 2023",
  },
  {
    title: "Announcement name goes here",
    name: "Meeting Agenda!",
    text: "Lorem ipsum dolor sit amet consectetur. Quis nulla dolor sed nibh accumsan. Ullamcorper viverra ornare a diam turpis habitasse. Felis nisl molestie aliquam eget elementum eros risus. Vitae malesuada turpis cursus pellentesque facilisi. Arcu cursus porttitor id ut dignissim venenatis. Pellentesque et risus at magnis dui et pharetra eu. Sem tortor congue.",
    date: "Oct 18, 2023",
  },
];

function Announcement() {
  const [OpenAnnouncementForm, setOpenAnnouncementForm] = useState(false);

  return (
    <div className="p-6 cursor-pointer">
      <div className="flex justify-between items-center">
        <div className="text-zinc-900 text-lg font-medium font-kas my-4">
          Announcement History
        </div>
        <div
          onClick={() => setOpenAnnouncementForm(true)}
          className="flex justify-center items-center bg-[#008080] mr-5 py-3 rounded-lg px-3"
        >
          <div className="text-center text-white text-md font-medium font-new leading-normal flex">
            <Icon
              icon="carbon:send-alt-filled"
              className="text-white mr-1 h-6 w-6"
            />
            Send Announcement
          </div>
        </div>
      </div>

      <div className="mt-5">
        {announcements.map((announcement, index) => (
          <div className="bg-white shadow-md rounded-xl p-5 mb-4">
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <div>
                  <Icon
                    icon="pepicons-pop:circle"
                    className="mr-1 text-[#008080] h-5 w-5"
                  />
                </div>
                <div className="w-[254px] text-[#008080] text-sm font-semibold font-kas leading-none">
                  {announcement.title}
                </div>
              </div>
              <div className="w-[88px] text-neutral-500 text-sm font-normal font-['Poppins'] leading-[21px]">
                {announcement.date}
              </div>
            </div>

            <div className="w-[600px] text-zinc-900 text-[15px] font-medium font-kas leading-tight ml-1 mt-3 mb-2">
              {announcement.name}
            </div>

            <div className="flex justify-between items-center">
              <div className="w-[600px] text-neutral-500 text-[13px] font-normal font-kas leading-normal ml-1 ">
                {announcement.text}
              </div>
              <div className="flex items-center">
                <div>
                  <Icon
                    icon="solar:users-group-two-rounded-linear"
                    className="mr-1 text-neutral-500 h-5 w-5"
                  />
                </div>
                <div className="text-neutral-500 text-sm font-normal font-['Poppins'] leading-tight">
                  3 Individuals
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Modal
        openModal={OpenAnnouncementForm}
        closeModal={setOpenAnnouncementForm}
      >
        <AnnouncementForm />
      </Modal>
    </div>
  );
}

export default Announcement;

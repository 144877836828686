import { Chart, registerables } from "chart.js";
import React, { useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";

const currentYear = new Date().getFullYear();
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

Chart.register(...registerables);

const BarChart = ({ input }) => {
  const [rawData, setRawData] = useState([]);
  const [year, setYear] = useState(currentYear);
  const [filteredData, setFilteredData] = useState([]);

  const memoData = useMemo(() => {
    return {
      labels: filteredData.map((item) => item.month),
      datasets: [
        {
          label: "Payments",
          backgroundColor: "rgba(0, 128, 128, 1)",
          borderColor: "rgba(0, 128, 128, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(0, 128, 128, 1)",
          hoverBorderColor: "rgba(0, 128, 128, 1)",
          data: filteredData.map((item) => item.total),
          borderRadius: 20,
          barThickness: 20,
        },
      ],
    };
  }, [filteredData]);

  // Chart options
  const options = {
    indexAxis: "x", // Horizontal bar chart
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        callbacks: {
          title: (ctx) => months.find((mth) => mth.startsWith(ctx[0].label)),
          label: (ctx) => `Total: ${ctx.formattedValue}`,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Remove x-axis grid lines
        },
      },
      y: {
        grid: {
          display: false, // Remove y-axis grid lines
        },
        beginAtZero: true,
      },
    },
    maintainAspectRatio: false, // Allow chart to be responsive to container size
    responsive: true,
    aspectRatio: 1, // Aspect ratio to control the height of the chart
  };

  // useEffect(() => {
  //   if (year) {
  //     setFilteredData(rawData.filter((data) => data.longMonth.includes(year)));
  //   }
  // }, [year]);

  useEffect(() => {
    if (input) {
      setRawData(input);
      setFilteredData(input.filter((data) => data.longMonth.includes(year)));
    }
  }, [input]);

  return (
    <>
      {/* <select name="" id=""></select> */}
      <Bar data={memoData} options={options} />
    </>
  );
};

export default BarChart;

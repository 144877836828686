import { zodResolver } from "@hookform/resolvers/zod";
import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { useMutate } from "../../hooks/useMutate";
import InputField from "../shared/InputField";

const schema = z.object({
  business_name: z.string().min(1, "Business name is required"),
  business_email: z.string().email(),
  business_phone_number: z
    .string()
    .length(10, "Please enter a valid phone number"),
  business_address: z.string(),
  business_city: z.string(),
  business_country: z.string(),
  client_phone_number: z
    .string()
    .length(10, "Please enter a valid phone number"),
  business_registration: z.any(),
});

const AddBusinessInfoForm = ({ setOpenModal, business }) => {
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      business_name: "",
      business_email: "",
      business_phone_number: "",
      business_address: "",
      business_city: "",
      business_country: "",
      client_phone_number: "",
      business_registration: "",
    },
    resolver: zodResolver(schema),
  });

  const { mutate } = useMutate(["add-business-info"]);
  const submit = (data) => {
    const toastId = toast.loading("Adding Business Info...");
    const formData = new FormData();
    Object.entries(data).forEach((item) => {
      formData.append(...item);
    });

    console.log(data);

    mutate(
      {
        url: business ? `/business/${business?._id}` : "/register/business/2",
        data: formData,
      },
      {
        onSuccess(data) {
          toast.dismiss(toastId);
          toast.success("Business registered successfully");
          setOpenModal(false);
        },
        onError(error) {
          toast.dismiss(toastId);
          toast.error(error.response.data.message);
        },
      }
    );
  };

  useEffect(() => {
    if (image) {
      setValue("business_registration", image);
      setImagePreview(URL.createObjectURL(image));
    }
  }, [image, setValue]);

  useState(() => {
    if (business) {
      setValue("business_name", business?.business_name);
      setValue("business_email", business?.business_email);
      setValue("business_phone_number", business?.business_phone_number);
      setValue("business_address", business?.business_address);
      setValue("business_city", business?.business_city);
      setValue("business_country", business?.business_country);
      setValue("client_phone_number", business?.client_phone_number);
    }
  }, [business, setValue]);

  console.log(errors);

  return (
    <div>
      <div className="mb-10">
        <h3 className="text-3xl font-bold text-gray-600">Business Info</h3>
        <p className="text-slate-500">
          Please enter the information for the business
        </p>
      </div>

      <form onSubmit={handleSubmit(submit)}>
        <div className="form-row">
          <InputField
            label="Business Name"
            name="business_name"
            register={register}
            errors={errors}
            required
          />
          <InputField
            type="email"
            label="Business Email"
            name="business_email"
            register={register}
            errors={errors}
            required
          />
        </div>

        <div className="form-row">
          <InputField
            label="Business Phone Number"
            name="business_phone_number"
            register={register}
            errors={errors}
            required
          />
          <InputField
            label="Business Address"
            name="business_address"
            register={register}
            errors={errors}
            required
          />
        </div>

        <div className="form-row">
          <InputField
            label="Business Location (City)"
            name="business_city"
            register={register}
            errors={errors}
            required
          />
          <InputField
            label="Country"
            name="business_country"
            register={register}
            errors={errors}
            required
          />
        </div>
        <div className="form-row">
          <InputField
            label="Business's Owner Phone Number"
            name="client_phone_number"
            register={register}
            errors={errors}
            required
          />
        </div>
        <div className="form-row">
          <div className="flex-1">
            {imagePreview ? (
              <div className="flex gap-5 flex-wrap justify-center py-3">
                <div className="relative h-32 w-32 shrink-0 rounded-md bg-slate-500">
                  <div className="overflow-hidden">
                    <img
                      src={imagePreview}
                      style={{ objectFit: "cover" }}
                      alt=""
                      className="w-32 h-32 rounded object-cover"
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <div className="w-full md:max-w-sm mx-auto">
              <label className="mb-1 block text-blue-900 text-md font-semibold leading-loose">
                Business Registration
              </label>
              <label
                htmlFor="dropzone-file-1"
                className={`flex-1 w-full flex items-center justify-center  border-2 border-primary bg-[#F4F6FB] border-dashed rounded-lg cursor-pointer p-2`}
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <Icon
                    icon="line-md:cloud-upload-outline-loop"
                    className="w-10 h-10 text-gray-400"
                  />
                  <div className="text-center text-sm">
                    <span className="text-blue-900 font-bold">
                      Drop the business registration image here, or
                    </span>
                    <span className="text-red-500 font-bold ml-2">browse</span>
                  </div>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    PNG, JPG and GIF files are allowed
                  </p>
                </div>
                <input
                  id="dropzone-file-1"
                  type="file"
                  className="hidden"
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-10">
          <button className="bg-primary text-white py-2 px-8">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default AddBusinessInfoForm;

import { Icon } from "@iconify/react";
import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import toast from "react-hot-toast";
import AddBusinessInfoForm from "../../components/forms/AddBusinessInfoForm";
import Modal from "../../components/shared/Modal";
import Spinner from "../../components/shared/Spinner";
import Table from "../../components/shared/Table";
import useConfirm from "../../hooks/useConfirm";
import { useGetQuery } from "../../hooks/useGetQuery";
import { useMutate } from "../../hooks/useMutate";
import AddBusiness from "./AddBusiness";
import BusinessDetails from "./BusinessDetails";

function Business() {
  const [business, setBusiness] = useState(null);
  const queryClient = useQueryClient();
  const { confirm, ConfirmationDialog, setIsOpen } = useConfirm();
  const [openBusinessDetail, setOpenBusinessDetail] = useState(false);
  const [openEditBusiness, setOpenEditBusiness] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { data, isLoading } = useGetQuery({
    url: `/businesses`,
    queryKey: ["businesses"],
  });

  const handleDetails = (business) => {
    setBusiness(business);
    setOpenBusinessDetail(true);
  };

  const handleEdit = (business) => {
    setBusiness(business);
    setOpenEditBusiness(true);
  };

  const { mutate } = useMutate(["delete-business"]);
  const handleDelete = async (business) => {
    const isConfirmed = await confirm({
      title: "Are you sure?",
      message: `Are you sure you want to delete "${business?.business_name}"?`,
    });

    if (isConfirmed) {
      const toastId = toast.loading(`Deleting ${business?.business_name}...`);

      mutate(
        {
          url: `/business/${business?._id}`,
          method: "DELETE",
        },
        {
          onSuccess(data) {
            queryClient.setQueryData(["businesses"], (oldData) => {
              return {
                message: (oldData?.message ?? []).filter(
                  (item) => item._id !== business?._id
                ),
              };
            });
            toast.dismiss(toastId);
            toast.success(`${business?.business_name} deleted successfully`);
            setIsOpen(false);
            setBusiness(null);
          },
          onError(error) {
            toast.dismiss(toastId);
            toast.error(error.response.data.message);
          },
        }
      );
    }
  };

  const columns = [
    {
      id: "SN",
      header: "SN",
      accessorKey: "",
      cell: (info) => <span className="px-2">{info.row.index + 1}</span>,
    },
    {
      header: "Business Name",
      accessorFn: (row) => `${row.business_name} ${row.business_email}`,
      cell: (info) => (
        <div className="px-2">
          <p className="leading-3">{info.row.original.business_name}</p>
          <span className="text-xs text-gray-600">
            {info.row.original.business_email}
          </span>
        </div>
      ),
    },
    {
      header: "Phone Number",
      accessorKey: "business_phone_number",
      cell: (info) => <span className="px-2">{info.getValue()}</span>,
    },
    {
      header: "Business Location",
      accessorKey: "business_city",
      cell: (info) => <span className="px-2">{info.getValue()}</span>,
    },
    {
      header: "Owner's Phone Number",
      accessorKey: "client_phone_number",
      cell: (info) => <span className="px-2">{info.getValue()}</span>,
    },
    {
      header: "Details",
      cell: (info) => (
        <button
          onClick={() => handleDetails(info.row.original)}
          className=" border border-primary rounded-md font-kas text-xs text-primary py-1 px-3 hover:bg-primary hover:text-white duration-300"
        >
          Details
        </button>
      ),
    },
    {
      header: "Actions",
      cell: (info) => (
        <div className="px-2 w-[%] flex gap-3">
          <Icon
            onClick={() => handleEdit(info.row.original)}
            icon="iconamoon:edit-light"
            className="text-xl"
          />
          <Icon
            onClick={() => handleDelete(info.row.original)}
            icon="fluent:delete-48-regular"
            className="text-xl text-red-700"
          />
        </div>
      ),
    },
  ];

  return (
    <div className="p-4 cursor-pointer">
      <Spinner isSubmitting={isLoading} />
      <div className="text-zinc-900 text-lg font-medium font-kas my-4">
        Businesses Overview
      </div>
      <Table
        data={data?.message}
        columns={columns}
        actionButton={() => (
          <button
            onClick={() => setOpenModal(true)}
            className="bg-primary text-white text-xs py-3 px-8 rounded-lg"
          >
            Add Business
          </button>
        )}
      />
      <ConfirmationDialog />

      {/* Business Details */}
      <Modal
        start
        width="w-full"
        openModal={openBusinessDetail}
        closeModal={setOpenBusinessDetail}
      >
        <BusinessDetails
          business={business}
          openDetails={openBusinessDetail}
          closeDetails={setOpenBusinessDetail}
          handleDelete={handleDelete}
        />
      </Modal>

      {/* Add Business Form */}
      <AddBusiness openModal={openModal} setOpenModal={setOpenModal} />

      {/* Edit Business */}
      <Modal
        start
        width="max-w-3xl"
        openModal={openEditBusiness}
        closeModal={setOpenEditBusiness}
      >
        <AddBusinessInfoForm business={business} />
      </Modal>
    </div>
  );
}

export default Business;

import { Icon } from "@iconify/react";
import React from "react";

function MemberDetails({ member }) {
  return (
    <div className="p-4 cursor-pointer w-full">
      <div className="h-[17rem] bg-white rounded-lg shadow-md relative">
        <div className="h-40 bg-primary rounded-lg">
          <div className="flex justify-end text-white text-2xl font-semibold font-kas leading-tight">
            <div className="mt-16 mr-10">Member Details</div>
          </div>
        </div>

        <div className="absolute top-0 left-0 w-full h-52 object-cover rounded-t-lg flex">
          <div className="mt-32 ml-3 h-32 w-32 bg-white flex shadow justify-center items-center rounded-md overflow-hidden">
            {member?.profile_image ? (
              <img
                src={member?.profile_image}
                alt=""
                className="h-32 w-32 object-cover rounded-md"
              />
            ) : (
              <Icon
                icon="lets-icons:user-duotone"
                className="text-gray-600"
                fontSize={200}
              />
            )}
          </div>
          <div className="mt-[10.4rem] ml-3">
            <div className="text-zinc-900 text-xl font-bold font-kas leading-normal">
              {member?.first_name} {member?.last_name}
            </div>
            <div className="text-zinc-900 text-sm font-medium font-kas leading-none mt-1">
              {member?.email}
            </div>
            <div className="flex items-center space-x-5">
              <div className="flex items-center mt-3 w-40">
                <Icon icon="bi:phone" className="h-5 w-5 mr-1" />
                <div className="text-neutral-500 text-sm font-medium font-kas leading-none">
                  {member?.phone_number}
                </div>
              </div>
              <div className="flex items-center mt-3 w-44">
                <div>
                  <Icon
                    icon="mdi:smart-card-outline"
                    className="h-5 w-5 mr-2"
                  />
                </div>
                <div className="text-neutral-500 text-sm font-medium font-kas leading-none">
                  {/* {member?.ghana_card_id} */}
                  GHA-123456789-0
                </div>
              </div>
              <div className="flex items-center mt-3 w-44">
                <div className="text-neutral-500 text-sm font-medium font-kas leading-none">
                  No. Of Businesses: {member?.businesses?.length}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" bg-white shadow-lg mt-6 rounded-lg p-6">
        <div className="text-zinc-900 text-lg font-bold font-['Poppins'] leading-tight mb-5">
          Businesses ({member?.businesses?.length})
        </div>
        <div className="flex gap-5 flex-wrap">
          {member?.businesses?.map((business, index) => (
            <div
              key={index}
              className=" bg-white rounded-lg border p-4 flex items-center w-80"
            >
              <div>
                <img
                  src="/images/d2.jpg"
                  alt=""
                  className="h-20 w-20 object-cover rounded-full"
                />
              </div>
              <div className="ml-3 mt-2">
                <div className="text-zinc-900 text-sm font-bold font-kas leading-tight">
                  {business.business_name}
                </div>
                <div className="flex items-center mt-3">
                  <div className="text-neutral-500 text-sm font-medium font-kas leading-none">
                    {business.business_email}
                  </div>
                </div>
                <div className="flex items-center mt-3">
                  <div className="text-neutral-500 text-sm font-medium font-kas leading-none">
                    {business.business_phone_number}
                  </div>
                </div>
                <div className="flex items-center mt-3">
                  <div>
                    <Icon
                      icon="system-uicons:location"
                      className="h-5 w-5 mr-1"
                    />
                  </div>
                  <div className="text-neutral-500 text-sm font-medium font-kas leading-none">
                    {business.business_city}, {business.business_country}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MemberDetails;

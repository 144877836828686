import React from "react";
import useUserStore from "../store/useUserStore";
import { Navigate } from "react-router-dom";

function Protected({ children }) {
  const { user } = useUserStore();

  if (!user?.auth_token) {
    return <Navigate to="/signin" replace />;
  }

  return children;
}

export default Protected;

import { Icon } from "@iconify/react";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { usePermission } from "../../hooks/usePermission";
import SubMenu from "./SubMenu";

export default function Sidebar() {
  const { allowedPermission } = usePermission();
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/signin");
  };

  return (
    <div className="z-30 h-screen w-60 fixed flex flex-col left-0 top-0 overflow-y-scroll cursor-pointer">
      <div className=" top h-[30px] flex items-center justify-center ">
        <Link to="/">
          <div className="flex justify-start items-center mt-20 p-2  border-b border-white border-opacity-50">
            <img src="/images/logo.png" alt="" className="w-16 h-15 mr-28" />
          </div>
        </Link>
      </div>
      <div className="overflow-y-scroll mt-[5rem] h-screen">
        <div className="">
          {allowedPermission.map((item, index) => {
            return <SubMenu item={item} key={index} location={location} />;
          })}
        </div>
      </div>

      <div
        onClick={handleLogout}
        className="flex items-center mb-6 justify-center mr-8"
      >
        <div>
          <Icon icon="solar:logout-linear" className="h-5 w-5 mr-3 " />
        </div>
        <div className="text-zinc-700 text-md font-semibold font-kas leading-normal">
          Logout
        </div>
      </div>
    </div>
  );
}

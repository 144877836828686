import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./components/layout/Layout";
import Business from "./pages/Businesses/Business";
import BusinessDetails from "./pages/Businesses/BusinessDetails";
import Members from "./pages/Members/Members";
import Payments from "./pages/Payment/Payments";
import Announcement from "./pages/announcement/Announcement";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import GroupDetails from "./pages/groups/GroupDetails";
import Groups from "./pages/groups/Groups";
import Roles from "./pages/roles/Roles";
import Settings from "./pages/settings/Settings";
import Users from "./pages/users/Users";
import Welfare from "./pages/welfare/Welfare";
import UserDetails from "./pages/users/UserDetails";
import Dashboard from "./pages/dashboard/Dashboard";

function App() {
  const [client] = useState(() => new QueryClient());

  return (
    <QueryClientProvider client={client}>
      <BrowserRouter>
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />

          <Route element={<Layout />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/payments" element={<Payments />} />

            <Route path="/business" element={<Business />} />
            <Route path="/business/:businessId" element={<BusinessDetails />} />

            <Route path="/welfares" element={<Welfare />} />
            <Route path="/announcements" element={<Announcement />} />

            <Route path="/members" element={<Members />} />
            {/* <Route path="/members/:memberId" element={<MembersDetails />} />
             */}

            <Route path="/users" element={<Users />} />
            <Route path="/users/:userId" element={<UserDetails />} />

            <Route path="/groups" element={<Groups />} />
            <Route path="/groups/:groupId" element={<GroupDetails />} />

            <Route path="/roles" element={<Roles />} />
            <Route path="/settings" element={<Settings />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <Toaster />
    </QueryClientProvider>
  );
}

export default App;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import InputField from "../shared/InputField";
import { useMutate } from "../../hooks/useMutate";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";
import Spinner from "../shared/Spinner";
import { useQueryClient } from "@tanstack/react-query";

function GroupForm({ group, closeModal }) {
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: group ? group.name : "",
    },
  });

  const navigate = useNavigate();
  const { mutate } = useMutate(["register"]);
  const submit = (data) => {
    setIsSubmitting(true);
    mutate(
      {
        url: group ? `/group/${group?._id}` : "/add/group",
        data,
        method: group ? "PATCH" : "POST",
      },
      {
        onSuccess(data) {
          queryClient.setQueryData(["groups"], (oldData) => {
            if (group) {
              return {
                message: [...(oldData?.message ?? [])].map((item) => {
                  if (item._id === group._id) {
                    return data.message;
                  } else {
                    return item;
                  }
                }),
              };
            } else {
              return {
                message: [...(oldData?.message ?? []), data?.message],
              };
            }
          });
          setIsSubmitting(false);
          closeModal();
          navigate("/groups");
        },
        onError(error) {
          console.log({ error });
          toast.error(error.response.data.message.message);
          setIsSubmitting(false);
        },
      }
    );
  };

  return (
    <div>
      <Spinner isSubmitting={isSubmitting} />
      <form onSubmit={handleSubmit(submit)}>
        <div className="w-[485px] text-zinc-900 text-2xl font-semibold font-kas leading-loose">
          {group ? "Update" : "Add"} Group
        </div>
        <div className="w-[485px] text-neutral-500 text-sm font-normal font-kas leading-[21px]">
          These will only take you few minutes
        </div>

        <div className="mt-7  ">
          <InputField
            name="name"
            label="Group Name"
            placeholder="Enter Group Name "
            errorMessage="Please Enter Your Group Name"
            errors={errors}
            register={register}
            required
          />
        </div>

        <div className="mt-6 flex justify-between gap-5">
          <button
            type="button"
            onClick={() => closeModal()}
            className="flex-1 rounded-xl font-kas text-sm border"
          >
            Cancel
          </button>
          <button className="flex-1 rounded-xl font-kas text-sm bg-primary text-white py-2">
            {group ? "Update" : "Add"} Group
          </button>
        </div>
      </form>
    </div>
  );
}

export default GroupForm;

import React from "react";
import { useForm } from "react-hook-form";
import InputField from "../shared/InputField";

function AnnouncementForm() {
  const {
    register,
    formState: { errors },
  } = useForm();

  return (
    <div>
      <form>
        <div className="w-[485px] text-zinc-900 text-2xl font-semibold font-kas leading-loose">
          Send Announcement
        </div>
        <div className="w-[485px] text-neutral-500 text-sm font-normal font-kas leading-[21px]">
          These will only take you few minutes
        </div>

        <div className="mt-7  ">
          <InputField
            name="Announcement  Title"
            label="Announcement  Title"
            placeholder="Phnes shop bill "
            errors={errors}
            register={register}
            required
          />

          <div className="mt-5">
            <div className="text-zinc-800 text-sm font-medium font-kas leading-tight mb-2">
              Description
            </div>
            <textarea
              rows="6"
              cols="70"
              className=" bg-white w-full outline-none border border-gray-200 rounded-xl py-3 pl-4 pr-3 sm:text-sm"
            ></textarea>
          </div>
        </div>

        <div className="text-neutral-900 text-sm font-medium font-['Poppins'] leading-[18px] tracking-wide mt-5">
          Send announcement by
        </div>

        <div className="mt-4 space-y-4">
          <div className="flex items-center">
            <input
              type="checkbox"
              name="All"
              label="All"
              errors={errors}
              register={register}
              required
              className="accent-green-400"
            />
            <div className="text-neutral-900 text-[15px] font-normal font-kas leading-[18px] ml-2">
              All
            </div>
          </div>
          <div className="border-b "></div>
          <div className="flex items-center">
            <input
              type="checkbox"
              name="All"
              errors={errors}
              register={register}
              required
              className="accent-green-400"
            />
            <div className="text-neutral-900 text-[15px] font-normal font-kas leading-[18px] ml-2">
              Individuals
            </div>
          </div>

          <div className="border-b "></div>

          <div className="flex items-center">
            <input
              type="checkbox"
              name="All"
              label="All"
              errors={errors}
              register={register}
              required
              className="accent-green-400"
            />
            <div className="text-neutral-900 text-[15px] font-normal font-kas leading-[18px] ml-2">
              Category
            </div>
          </div>
        </div>

        <div className="mt-6 flex justify-between">
          <div className="flex justify-center items-center mr-5 rounded-xl px-3 border w-full">
            <div className="text-neutral-900 text-sm font-bold font-kas capitalize leading-10">
              Cancel
            </div>
          </div>

          <div className="flex justify-center items-center bg-[#008080] rounded-xl px-10 w-full">
            <div className="text-center text-white text-sm font-medium font-kas font-new leading-normal flex ">
              Send Now
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AnnouncementForm;

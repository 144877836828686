import { Icon } from "@iconify/react";
import { format } from "date-fns";
import React from "react";
import Box from "../../components/shared/Box";
import { capitalize } from "../../utils/capitalize";

const PaymentDetails = ({ payment }) => {
  console.log(payment);
  return (
    <div className="p-4 cursor-pointer w-full">
      <div className="min-h-[17rem] bg-white rounded-lg shadow-md relative">
        <div className="h-40 bg-primary rounded-lg">
          <div className="flex justify-end text-white text-2xl font-semibold font-kas leading-tight">
            <div className="mt-16 mr-10">Payment Details</div>
          </div>
        </div>

        <div className="relative -top-5 left-0 w-full object-cover rounded-t-lg flex items-center">
          <div className="ml-3 h-32 shrink-0 w-32 bg-white flex shadow justify-center items-center rounded-md overflow-hidden">
            {payment?.profile_image ? (
              <img
                src={payment?.profile_image}
                alt=""
                className="h-32 w-32 object-cover rounded-md"
              />
            ) : (
              <Icon
                icon="lets-icons:user-duotone"
                className="text-gray-600"
                fontSize={200}
              />
            )}
          </div>
          <div className="ml-3 w-full pt-2">
            <div className="text-zinc-900 text-xl font-bold font-kas leading-normal">
              {payment?.payer.business_name}
            </div>
            <div className="text-zinc-900 text-sm font-medium font-kas leading-none mt-1">
              {payment?.payer.business_email}
            </div>
            <div className="grid grid-auto-fit-sm">
              <div className="flex items-center mt-3 w-full">
                <Icon icon="bi:phone" className="h-5 w-5 mr-1" />
                <div className="text-neutral-500 text-sm font-medium font-kas leading-none">
                  {payment?.payer.business_phone_number}
                </div>
              </div>
              <div className="flex items-center mt-3 w-full">
                <Icon icon="ci:users-group" className="h-5 w-5 mr-2" />
                <div className="text-neutral-500 text-sm font-medium font-kas leading-none">
                  {payment?.payer.group?.name}
                </div>
              </div>
              <div className="flex items-center mt-3">
                <div>
                  <Icon
                    icon="system-uicons:location"
                    className="h-5 w-5 mr-1"
                  />
                </div>
                <div className="text-neutral-500 text-sm font-medium font-kas leading-none">
                  {payment?.payer.business_city}, {""}
                  {payment?.payer.business_country}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" bg-white shadow-lg mt-6 rounded-lg p-6">
        <div className="text-zinc-900 text-lg font-bold font-['Poppins'] leading-tight mb-5">
          Transaction Details
        </div>
        <div className="grid gap-5 grid-auto-fit-sm">
          <Box label="Transaction ID" value={payment?.transactionId} />
          <Box label="Phone Number" value={payment?.phone_number} />
          <Box label="Network" value={payment?.network.toUpperCase()} />
          <Box label="Amount" value={payment?.amount} />
          <Box label="Currency" value={payment?.currency} />
          <Box label="Purpose" value={capitalize(payment?.purpose)} />
          <Box label="Status" value={payment?.status} />
          <Box
            label="Approved"
            value={payment?.isApproved ? "Approved" : "Not Approved"}
          />
          <Box
            label="Date Paid"
            value={format(new Date(payment?.date_updated ?? ""), "PPP")}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;

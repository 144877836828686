import React from "react";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

function SignUp() {
  return (
    <div className="flex justify-center items-center h-screen bg-neutral-50 cursor-pointer">
      <div className=" w-[30%] shadow-md rounded-lg px-10 py-7">
        <div className="">
          <div className="flex justify-center">
            <img src="/images/kasoalogo.png" alt="" />
          </div>
          <div className="flex justify-center mt-3">
            <div className="text-zinc-900 text-2xl font-semibold font-kas leading-loose">
              Get Started with
            </div>
            <div className="text-[#008080] text-2xl font-semibold font-kas leading-loose ml-1">
              Kasoa
            </div>
          </div>
          <div className=" text-center text-neutral-500 text-base font-normal font-kas leading-relaxed flex justify-center mb-3">
            Create your free account
          </div>
        </div>
        <form className="">
          <div className="mt-5">
            <div className="flex items-center bg-neutral-100 w-full outline-none border-gray-200 rounded-xl mt-6">
              <div className="pl-3">
                <Icon icon="solar:user-linear" className="mr-1 h-5 w-5" />
              </div>
              <input
                type="text"
                placeholder="Your Full Name"
                className=" placeholder:text-zinc-700 placeholder:text-base placeholder:font-kas placeholder:font-normal block bg-neutral-100 w-full outline-none border-gray-200 rounded-xl py-3 pl-2 pr-3 sm:text-sm h-12"
              />
            </div>
            <div className="flex items-center bg-neutral-100 w-full outline-none border-gray-200 rounded-xl mt-6">
              <div className="pl-3">
                <Icon icon="solar:user-linea" className="mr-1 h-5 w-5" />
              </div>
              <input
                type="text"
                placeholder="+233   |   0 00 00 00 00"
                className=" placeholder:text-zinc-700 placeholder:text-base placeholder:font-kas placeholder:font-normal block bg-neutral-100 w-full outline-none border-gray-200 rounded-xl py-3 pl-2 pr-3 sm:text-sm h-12"
              />
            </div>
            <div className="flex items-center bg-neutral-100 w-full outline-none border-gray-200 rounded-xl mt-6">
              <div className="pl-3">
                <Icon
                  icon="solar:lock-password-linear"
                  className="mr-1 h-5 w-5"
                />
              </div>
              <input
                type="text"
                placeholder="Create a Strong Password"
                className=" placeholder:text-zinc-700 placeholder:text-base placeholder:font-kas placeholder:font-normal block bg-neutral-100 w-full outline-none border-gray-200 rounded-xl py-3 pl-2 pr-3 sm:text-sm h-12"
              />
              <div className="pr-3">
                <Icon icon="mdi:eye-off-outline" className="mr-1 h-6 w-6" />
              </div>
            </div>
          </div>

          <div className="flex justify-center">
            <div className="flex justify-center text-neutral-500 text-sm font-normal font-kas leading-snug mt-14 ">
              Already have an account?
            </div>
            <Link to="/signin">
              <div className="flex justify-center text-[#008080] text-sm font-semibold font-kas leading-snug mt-14 ml-1">
                SignIn
              </div>
            </Link>
          </div>

          <div className="flex justify-center mt-10">
            <div className="flex justify-center items-center bg-[#008080] mr-5 py-3 rounded-lg px-3 w-40">
              <div className="text-center text-white font-kas text-sm font-medium font-new leading-normal">
                Sign Up
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignUp;

import React from "react";
import { Avatar } from "../../components/shared/Avatar";
import Table from "../../components/shared/Table";

function GroupDetails({ group }) {
  const columns = [
    {
      id: "SN",
      header: "SN",
      accessorKey: "",
      cell: (info) => <span className="px-1">{info.row.index + 1}</span>,
    },
    {
      header: "Name",
      id: "Name",
      accessorFn: (row) => `${row.f_name} ${row.l_name} ${row.email}`,
      cell: (props) => (
        <div className="flex items-center">
          <div>
            {props.row.original.profile_image === null ||
            props.row.original.profile_image === "null" ? (
              <Avatar size={45} />
            ) : (
              <img
                src={props.row.original.profile_image}
                alt=""
                className="h-12 w-12 object-cover rounded-full"
              />
            )}
          </div>
          <div className="ml-2">
            <div className=" text-blue-900 text-[15px] font-bold leading-snug">
              {props.row.original.first_name} {props.row.original.last_name}
            </div>
            <div className=" text-slate-400 text-sm font-normal leading-tight">
              {props.row.original.email}
            </div>
          </div>
        </div>
      ),
    },
    {
      header: "Phone Number",
      accessorKey: "phone_number",
    },
    {
      id: "no_of_businesses",
      header: () => (
        <span className="w-full text-center block">No. of Businesses</span>
      ),
      accessorFn: (row) => row.businesses?.length,
      cell: (info) => (
        <span className="block text-center">{info.getValue()}</span>
      ),
    },
    {
      header: "Date Joined",
      accessorKey: "date_created",
    },
  ];
  return (
    <div className="p-4 cursor-pointer w-full">
      <div className="flex justify-end p-10 items-center h-40 bg-primary rounded-lg">
        <div className="text-right text-white font-semibold">
          <h3 className="text-3xl">Group Details</h3>
          <p className="text-xl capitalize mt-2">
            {group?.name?.toUpperCase()} GROUP
          </p>
        </div>
      </div>
      <div className="shadow-md">
        <Table columns={columns} data={group?.members ?? []} />
      </div>
    </div>
  );
}

export default GroupDetails;

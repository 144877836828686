import { Icon } from "@iconify/react";
import React from "react";

export const Avatar = ({ size = 30 }) => {
  return (
    <Icon
      icon="material-symbols:account-circle"
      className="text-gray-600"
      fontSize={size}
    />
  );
};

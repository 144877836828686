import React, { useState } from "react";
import AddBusinessInfoForm from "../../components/forms/AddBusinessInfoForm";
import AddBusinessOwnerForm from "../../components/forms/AddBusinessOwnerForm";
import Modal from "../../components/shared/Modal";

const AddBusiness = ({ openModal, setOpenModal }) => {
  const [view, setView] = useState("Owner's Details");

  return (
    <Modal
      start
      width="max-w-3xl"
      openModal={openModal}
      closeModal={setOpenModal}
    >
      <div className="bg-white p-10 rounded-md">
        {view === "Owner's Details" ? (
          <AddBusinessOwnerForm setView={setView} />
        ) : (
          <AddBusinessInfoForm setOpenModal={setOpenModal} />
        )}
      </div>
    </Modal>
  );
};

export default AddBusiness;

import React from "react";
import Box from "../../components/shared/Box";

function UserDetails({ user }) {
  return (
    <div>
      <div className="p-4 cursor-pointer w-full">
        <div className=" bg-white rounded-lg shadow-md relative">
          <div className="h-40 bg-primary rounded-lg">
            <div className="flex justify-end text-white text-2xl font-semibold font-kas leading-tight">
              <div className="mt-16 mr-10">User Details</div>
            </div>
          </div>
        </div>

        <div className=" bg-white shadow-lg mt-6 rounded-lg p-6">
          <div className="text-zinc-900 text-lg font-bold font-['Poppins'] leading-tight mb-5">
            User Details
          </div>
          <div className="grid gap-5 grid-auto-fit-sm">
            <Box
              label="Months Paid"
              value={`${user?.first_name} ${user?.first_name}`}
            />
            <Box label="Email" value={user?.email} />
            <Box label="Phone Number" value={user?.phone_number} />
            <Box label="Group" value={user?.role.name} />
          </div>
          <div className="mt-5">
            <Box
              label="Permissions"
              value={user?.role.permissions.join(", ")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDetails;

import { useState } from "react";
import { useRef } from "react";
import { Icon } from "@iconify/react";

const useConfirm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState({
    title: "",
    message: "",
    confirmButtonLabel: "",
    fullWidth: false,
  });
  const fn = useRef();

  const confirm = ({
    title = "",
    message = "",
    confirmButtonLabel = null,
    fullWidth = false,
  }) => {
    setState({
      title,
      message,
      confirmButtonLabel: confirmButtonLabel ?? "Yes, Delete!",
      fullWidth,
    });
    return new Promise((resolve) => {
      setIsOpen(true);
      fn.current = (choice) => {
        if (choice) {
          resolve(choice);
        } else {
          resolve(choice);
          setIsOpen(false);
        }
      };
    });
  };

  // You could replace the Dialog with your library's version
  const ConfirmationDialog = () => (
    <div
      className={`flex items-center justify-center fixed top-0 right-0 bottom-0 left-60 min-h-screen p-5 overflow-y-auto bg-black/50 z-50 transform ${
        isOpen ? "scale-100 opacity-100" : "scale-0 opacity-0"
      } duration-500`}
    >
      <div className="bg-white max-w-md w-full p-5 rounded-md font-kas">
        <div className="flex justify-center mb-5">
          <Icon icon={"line-md:alert-circle"} className="text-5xl text-[red]" />
        </div>
        <div className="text-center mb-5">
          <h3 className="font-bold text-3xl font-kas">{state.title}</h3>
          <p className="text-lg text-gray-700 mt-2 font-kas">{state.message}</p>
        </div>
        <div className="flex gap-5 items-center justify-center">
          <button
            className="bg-red-700 text-white px-5 py-2 rounded-md font-kas"
            onClick={() => (fn.current ? fn.current(true) : null)}
          >
            {state.confirmButtonLabel}
          </button>
          <button
            className="bg-gray-400 py-2 px-5 rounded-md font-kas"
            onClick={() => (fn.current ? fn.current(false) : null)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
  return { ConfirmationDialog, confirm, setIsOpen, isOpen };
};

export default useConfirm;

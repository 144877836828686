import { SidebarData } from "../components/sidebar/SidebarData";
import { useUser } from "./useUser";

export const usePermission = () => {
  const user = useUser();
  const { permissions } = user?.role;

  const allowedPermission = SidebarData.filter((data) =>
    permissions.includes(data.groupTitle)
  );

  return { allowedPermission };
};

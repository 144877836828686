import React from "react";
import { Icon } from "@iconify/react";

function SettingsInputField(props) {
  let {
    name,
    register,
    errors,
    label = "",
    type = "text",
    required = false,
    errorMessage = "",
    placeholder = "",
    icon = "pepicons-pop:circle",
    ...res
  } = props;

  return (
    <div className="flex-1">
      {label && (
        <label className="mb-2 block text-md font-sem font-kas leading-tight text-zinc-700 text-sm font-normal ml-2">
          {label}{" "}
          {!required && (
            <span className="text-slate-300 text-md">(Optional)</span>
          )}
        </label>
      )}
      <div className="flex items-center bg-neutral-50 w-full outline-none border-gray-200 rounded-xl">
        <div className="pl-3">
          <Icon icon={icon} className="mr-1 h-5 w-5" />
        </div>
        <input
          type={type}
          placeholder={placeholder}
          className=" placeholder:text-zinc-700 placeholder:text-base placeholder:font-normal block bg-neutral-50 w-full outline-none border-gray-200 rounded-xl py-3 pl-2 pr-3 sm:text-sm h-12"
          {...register(name, {
            ...(required && {
              required: {
                value: true,
                message: errorMessage,
              },
            }),
          })}
          {...res}
        />
      </div>
      {errors[name] && (
        <span className="text-red-500 text-[12px]">{errors[name].message}</span>
      )}
    </div>
  );
}

export default SettingsInputField;

import React from "react";
import { DotLoader } from "react-spinners";

function Spinner({ isSubmitting }) {
  if (isSubmitting) {
    return (
      <div className="fixed top-0 right-0 bottom-0 left-60 bg-neutral-700/30 z-50 flex justify-center items-center">
        <DotLoader color="#008080" />
      </div>
    );
  } else {
    return null;
  }
}

export default Spinner;

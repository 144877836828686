import React from "react";
import Sidebar from "../sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Protected from "../../hooks/Protected";

const Layout = () => {
  return (
    <Protected>
      <Sidebar />
      <Header />
      <main className="ml-60 font-ray overflow-y-auto min-h-screen relative pt-16 pb-10 bg-no-repeat bg-cover bg-[#f4f3f5]">
        <Outlet />
      </main>
    </Protected>
  );
};

export default Layout;

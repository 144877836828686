import React, { useState } from "react";
import WelfareForm from "../../components/forms/WelfareForm";
import Modal from "../../components/shared/Modal";
import Spinner from "../../components/shared/Spinner";
import Table from "../../components/shared/Table";
import { useGetQuery } from "../../hooks/useGetQuery";

function Welfare() {
  const [OpenWelfareForm, setOpenWelfareForm] = useState(false);

  const { data, isLoading } = useGetQuery({
    url: "/welfares",
    queryKey: ["welfares"],
  });

  const columns = [
    {
      header: "SN",
      accessorKey: "",
      cell: (info) => <span className="px-1">{info.row.index + 1}</span>,
    },
    {
      header: "Transaction ID",
      accessorKey: "transactionId",
    },
    {
      header: "Payer Name",
      accessorKey: "payer_name",
    },
    {
      header: "Shop Name",
      accessorKey: "shop_name",
    },
    {
      header: () => <span className="block w-full text-center">Amount</span>,
      accessorKey: "welfare_amount",
      cell: (info) => (
        <span className="block text-center">{info.getValue()}</span>
      ),
    },
    {
      header: "Date Paid",
      accessorKey: "date_created",
    },
  ];

  return (
    <div className="p-4 cursor-pointer">
      <Spinner isSubmitting={isLoading} />

      <div className="text-zinc-900 text-lg font-medium font-kas my-4">
        Welfare Overview
      </div>

      <Table columns={columns} data={data?.message} />

      <Modal openModal={OpenWelfareForm} closeModal={setOpenWelfareForm}>
        <WelfareForm />
      </Modal>
    </div>
  );
}

export default Welfare;

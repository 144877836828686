import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useUser } from "./useUser";

export const useMutate = ({ queryKey = [] }) => {
  const user = useUser();
  return useMutation({
    queryKey: [...queryKey],
    mutationFn: async ({ url, data, method = "POST", multipart = false }) => {
      const res = await axios(`${process.env.REACT_APP_BASE_URL}${url}`, {
        method,
        data,
        headers: {
          Authorization: `Bearer ${user?.auth_token}`,
          roleid: user?.role._id,
          "Content-Type": multipart
            ? "application/form-data"
            : "application/json",
        },
      });
      return res.data;
    },
  });
};

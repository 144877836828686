import { Icon } from "@iconify/react";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import GroupForm from "../../components/forms/CategoryForm";
import Modal from "../../components/shared/Modal";
import Spinner from "../../components/shared/Spinner";
import Table from "../../components/shared/Table";
import useConfirm from "../../hooks/useConfirm";
import { useGetQuery } from "../../hooks/useGetQuery";
import { useMutate } from "../../hooks/useMutate";
import GroupDetails from "./GroupDetails";

function Groups() {
  const queryClient = useQueryClient();
  const [group, setGroup] = useState(null);
  const { ConfirmationDialog, confirm, setIsOpen } = useConfirm();
  const [OpenGroupForm, setOpenGroupForm] = useState(false);
  const [openGroupDetails, setOpenGroupDetails] = useState(false);

  const { data, isLoading } = useGetQuery({
    url: "/groups",
    queryKey: ["groups"],
  });

  const handleDetails = (group) => {
    setGroup(group);
    setOpenGroupDetails(true);
  };

  const handleEdit = (group) => {
    setGroup(group);
    setOpenGroupForm(true);
  };

  const { mutate } = useMutate({ queryKey: ["delete-group"] });
  const handleDelete = async (group) => {
    const isConfirmed = await confirm({
      title: "Are You Sure?",
      message: `Are you sure you want to delete "${group?.name}" group?`,
    });

    if (isConfirmed) {
      const toastId = toast.loading(`Deleting ${group?.name} group...`);
      mutate(
        {
          url: `/group/${group?._id}`,
          method: "DELETE",
        },
        {
          onSuccess(data) {
            queryClient.setQueryData(["groups"], (oldData) => {
              return {
                message: (oldData?.message || []).filter(
                  (item) => item._id !== group?._id
                ),
              };
            });
            toast.dismiss(toastId);
            toast.success(`${group?.name} deleted successfully`);
            setIsOpen(false);
          },
          onError(error) {
            toast.dismiss(toastId);
            toast.error(error.response.data.message);
          },
        }
      );
    }
  };

  const columns = [
    {
      id: "SN",
      header: "SN",
      accessorKey: "",
      cell: (info) => <span className="px-1">{info.row.index + 1}</span>,
    },
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: () => (
        <span className="block text-center w-full">Number Of Members</span>
      ),
      accessorKey: "number_of_members",
      cell: (info) => (
        <span className="block text-center">{info.getValue()}</span>
      ),
    },
    {
      header: "Details",
      cell: (info) => (
        <button
          onClick={() => handleDetails(info.row.original)}
          className="border border-primary rounded-md text-xs text-primary py-1 px-3 hover:bg-primary hover:text-white duration-300"
        >
          Details
        </button>
      ),
    },
    {
      header: "Actions",
      cell: (info) => (
        <div className="w-[%] flex gap-5">
          <Icon
            onClick={() => handleEdit(info.row.original)}
            icon="iconamoon:edit-light"
            className="text-xl"
          />
          <Icon
            onClick={() => handleDelete(info.row.original)}
            icon="fluent:delete-48-regular"
            className="text-xl text-red-700"
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (!OpenGroupForm && !openGroupDetails) {
      setGroup(null);
    }
  }, [OpenGroupForm, openGroupDetails]);

  return (
    <div className="p-4 cursor-pointer">
      <Spinner isSubmitting={isLoading} />
      <div className="flex justify-between items-center">
        <div className="text-zinc-900 text-lg font-medium font-kas my-4">
          Group table
        </div>
        <div
          onClick={() => setOpenGroupForm(true)}
          className="flex justify-center items-center bg-primary mr-5 py-3 rounded-lg px-3"
        >
          <button className="text-center text-white text-md font-medium font-new leading-normal flex">
            <Icon icon="iconoir:plus" className="text-white mr-1 h-6 w-6" />
            Add Group
          </button>
        </div>
      </div>

      <div className="">
        <Table data={data?.message ?? []} columns={columns} />
      </div>

      <Modal openModal={OpenGroupForm} closeModal={setOpenGroupForm}>
        <GroupForm
          group={group}
          closeModal={() => {
            setGroup(null);
            setOpenGroupForm(false);
          }}
        />
      </Modal>

      <Modal
        start
        width="w-full"
        openModal={openGroupDetails}
        closeModal={setOpenGroupDetails}
      >
        <GroupDetails group={group} />
      </Modal>

      <ConfirmationDialog />
    </div>
  );
}

export default Groups;

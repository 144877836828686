import { format } from "date-fns";
import React, { useState } from "react";
import Modal from "../../components/shared/Modal";
import Spinner from "../../components/shared/Spinner";
import Table from "../../components/shared/Table";
import { useGetQuery } from "../../hooks/useGetQuery";
import { capitalize } from "../../utils/capitalize";
import PaymentDetails from "./PaymentDetails";

function Payments() {
  const [payment, setPayment] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const { data, isLoading } = useGetQuery({
    url: "/payments",
    queryKey: ["payments"],
    method: "POST",
  });

  const columns = [
    {
      id: "SN",
      header: "SN",
      accessorKey: "",
      cell: (info) => <span className="px-1">{info.row.index + 1}</span>,
    },
    {
      header: "Transaction ID",
      accessorKey: "transactionId",
    },
    {
      id: "businessName",
      header: "Business Name",
      accessorFn: (row) => row.payer.business_name,
      cell: (cell) => cell.getValue(),
    },
    {
      header: () => <span className="block text-center w-full">Amount</span>,
      accessorKey: "amount",
      cell: (info) => (
        <span className="block text-center">{info.getValue()}</span>
      ),
    },
    {
      header: "Purpose",
      accessorKey: "purpose",
      cell: (info) => capitalize(info.getValue()),
    },
    {
      header: "Date Paid",
      accessorKey: "date_updated",
      cell: (info) => (
        <span>{format(new Date(info.getValue() ?? ""), "PPP")}</span>
      ),
    },
    {
      header: "Details",
      cell: (info) => (
        <button
          onClick={() => {
            setPayment(info.row.original);
            setOpenModal(true);
          }}
          className="border border-primary rounded-md text-xs text-primary py-1 px-3 hover:bg-primary hover:text-white duration-300"
        >
          Details
        </button>
      ),
    },
  ];

  return (
    <div className="p-4 cursor-pointer">
      <Spinner isSubmitting={isLoading} />
      <div className="text-zinc-900 text-lg font-medium font-kas my-4">
        Payment Overview
      </div>

      <Table data={data?.message} columns={columns} />

      <Modal
        start
        width="w-full"
        openModal={openModal}
        closeModal={setOpenModal}
      >
        <PaymentDetails payment={payment} setPayment={setPayment} />
      </Modal>
    </div>
  );
}

export default Payments;

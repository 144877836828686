import _ from "lodash";

export const sortData = (data) => {
  const arr = _.chain(data)
    .groupBy((item) => {
      const datesArr = item.date_created.split(" ");
      return `${datesArr[1]} ${datesArr[2]}`;
    })
    .map((value, index) => ({
      month: index,
      total: value.reduce((acc, item) => acc + Number(item.amount), 0),
    }))
    .value();

  arr.sort((a, b) => new Date(a.month) - new Date(b.month));

  const result = _.map(arr, (value) => ({
    longMonth: value.month,
    month: value.month.substring(0, 3),
    total: value.total,
  }));

  return result;
};

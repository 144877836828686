import { Icon } from "@iconify/react";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import AddBusinessOwnerForm from "../../components/forms/AddBusinessOwnerForm";
import { Avatar } from "../../components/shared/Avatar";
import Modal from "../../components/shared/Modal";
import Spinner from "../../components/shared/Spinner";
import Table from "../../components/shared/Table";
import useConfirm from "../../hooks/useConfirm";
import { useGetQuery } from "../../hooks/useGetQuery";
import { useMutate } from "../../hooks/useMutate";
import MemberDetails from "./MemberDetails";

function Members() {
  const queryClient = useQueryClient();
  const { ConfirmationDialog, confirm, setIsOpen } = useConfirm();
  const [member, setMember] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openMembersForm, setOpenMembersForm] = useState(false);

  const { data, isLoading } = useGetQuery({
    url: "/members",
    queryKey: ["members"],
  });

  const handleEdit = (member) => {
    setMember(member);
    setOpenMembersForm(true);
  };

  const handleDetails = (member) => {
    setMember(member);
    setOpenModal(true);
  };

  const { mutate } = useMutate(["delete-member"]);
  const handleDelete = async (member) => {
    const isConfirmed = await confirm({
      title: "Are you sure?",
      message: `Are you sure you want to delete "${member?.first_name} ${member?.last_name}"?`,
    });

    if (isConfirmed) {
      const toastId = toast.loading(
        `Deleting ${member?.first_name} ${member?.last_name}...`
      );

      mutate(
        {
          url: `/member/${member?._id}`,
          method: "DELETE",
        },
        {
          onSuccess() {
            queryClient.setQueryData(["members"], (oldData) => {
              return {
                message: (oldData?.message ?? []).filter(
                  (item) => item._id !== member?._id
                ),
              };
            });
            toast.dismiss(toastId);
            toast.success(
              `${member?.first_name} ${member?.last_name} deleted successfully`
            );
            setIsOpen(false);
          },
          onError(error) {
            toast.dismiss(toastId);
            toast.error(error.response.data.message);
            setIsOpen(false);
          },
        }
      );
    }
  };

  const columns = [
    {
      id: "SN",
      header: "SN",
      accessorKey: "",
      cell: (info) => <span className="px-1">{info.row.index + 1}</span>,
    },
    {
      header: "Name",
      id: "Name",
      accessorFn: (row) => `${row.f_name} ${row.l_name} ${row.email}`,
      cell: (props) => (
        <div className="flex items-center">
          <div>
            {props.row.original.profile_image === null ||
            props.row.original.profile_image === "null" ? (
              <Avatar size={45} />
            ) : (
              <img
                src={props.row.original.profile_image}
                alt=""
                className="h-12 w-12 object-cover rounded-full"
              />
            )}
          </div>
          <div className="ml-2">
            <div className=" text-blue-900 text-[15px] font-bold leading-snug">
              {props.row.original.first_name} {props.row.original.last_name}
            </div>
            <div className=" text-slate-400 text-sm font-normal leading-tight">
              {props.row.original.email}
            </div>
          </div>
        </div>
      ),
    },
    {
      header: "Phone Number",
      accessorKey: "phone_number",
    },
    {
      id: "no_of_businesses",
      header: () => (
        <span className="w-full text-center block">No. of Businesses</span>
      ),
      accessorFn: (row) => row.businesses?.length,
      cell: (info) => (
        <span className="block text-center">{info.getValue()}</span>
      ),
    },
    {
      header: "Date Joined",
      accessorKey: "date_created",
    },
    {
      header: "Details",
      cell: (info) => (
        <button
          onClick={() => handleDetails(info.row.original)}
          className="border border-primary rounded-md font-kas text-xs text-primary py-1 px-3 hover:bg-primary hover:text-white duration-300"
        >
          Details
        </button>
      ),
    },
    {
      header: "Actions",
      cell: (info) => (
        <div className="w-[%] flex gap-3">
          <Icon
            onClick={() => handleEdit(info.row.original)}
            icon="iconamoon:edit-light"
            className="text-xl"
          />
          <Icon
            onClick={() => handleDelete(info.row.original)}
            icon="fluent:delete-48-regular"
            className="text-xl text-red-700"
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (!openModal && !openMembersForm) {
      setMember(null);
    }
  }, [openModal, openMembersForm]);

  return (
    <div className="p-4 cursor-pointer">
      <Spinner isSubmitting={isLoading} />
      <div className="flex justify-between items-center">
        <div className="text-zinc-900 text-lg font-medium font-kas my-4">
          Members table
        </div>
        <div
          onClick={() => setOpenMembersForm(true)}
          className="flex justify-center items-center bg-[#008080] mr- py-3 rounded-lg px-3"
        >
          <div className="text-center text-white text-md font-medium font-new leading-normal flex">
            <Icon icon="iconoir:plus" className="text-white mr-1 h-6 w-6" />
            Add Member
          </div>
        </div>
      </div>

      <Table columns={columns} data={data?.message} />

      <Modal
        start
        width="max-w-3xl"
        openModal={openMembersForm}
        closeModal={setOpenMembersForm}
      >
        <AddBusinessOwnerForm owner={member} />
      </Modal>

      {/* Member Details */}
      <Modal
        start
        width="w-full"
        openModal={openModal}
        closeModal={setOpenModal}
      >
        <MemberDetails member={member} setMember={setMember} />
      </Modal>

      <ConfirmationDialog />
    </div>
  );
}

export default Members;

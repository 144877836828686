import { Icon } from "@iconify/react";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { IoLogOutOutline, IoSettingsOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const menuRef = useRef();
  const imgRef = useRef();

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(e.target) &&
        imgRef.current &&
        !imgRef.current.contains(e.target)
      ) {
        setOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    // dispatch(clearUser());
    navigate("/signin");
  };

  return (
    <div className="z-10 fixed flex bg-white h-16 items-center w-[84%] ml-60 cursor-pointer p-3">
      <div className="flex justify-between items-center bg-white h-12 rounded-xl text-slate-700 text-[24px] font-bold leading-loose item-center w-full">
        <div className="text-zinc-900 text-xl font-medium font-kas leading-loose ml-2">
          Dashboard
        </div>
        <div className="flex items-center">
          <div className="h-8 w-72 bg-[#F4F6FB] flex mt-2 ml-5 mr-5 rounded-lg">
            <Icon icon="circum:search" className="mt-[0.4rem] h-5 w-5 ml-3" />

            <input
              type="text"
              name="name"
              placeholder="Search "
              className="bg-[#F4F6FB] w-40 outline-none p-2 placeholder:text-gray-700 text-sm placeholder:font-normal placeholder:mb-3"
            />
          </div>

          <img
            className="w-9 h-9 mr-4 ml-2 mt-2 rounded-full object-cover cursor-pointer"
            src="/images/employee.jpg"
            alt=""
          />

          <div className=" flex items-center mt-1">
            <div className="text-neutral-900 text-base font-semibold font-kas leading-normal tracking-tight">
              Akuah Kwojo
            </div>
            <div ref={imgRef} onClick={() => setOpen(!open)} className="">
              <Icon icon="ep:arrow-down" className="h-4 w-4 ml-4" />
            </div>
          </div>
        </div>

        {open && (
          <div
            ref={menuRef}
            className="z-10 bg-white shadow-xl absolute h-40 w-60 mr- right-4 top-20 rounded-xl ease-in-out duration-150"
          >
            <ul className="text-lg font-normal space-y-4 font-kas font-sm mt-4">
              <Link to="/settings">
                <div className="flex items-center">
                  <AiOutlineUser size={25} className="ml-4 text-neutral-900" />
                  <li className="cursor-pointer ml-3">View profile</li>
                </div>
              </Link>

              <Link to="/settings">
                <div className="flex items-center mt-5">
                  <IoSettingsOutline
                    size={20}
                    className="ml-5 text-neutral-900"
                  />
                  <li className="cursor-pointer ml-3">Settings</li>
                </div>
              </Link>

              <div className="flex items-center mt-5" onClick={handleLogout}>
                <IoLogOutOutline size={20} className="ml-5 text-neutral-900" />
                <li className="cursor-pointer ml-3">Logout</li>
              </div>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;

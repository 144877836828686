import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useGetQuery } from "../../hooks/useGetQuery";
import { useMutate } from "../../hooks/useMutate";
import InputField from "../shared/InputField";
import Spinner from "../shared/Spinner";

function UserForm({ closeModal, user }) {
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [type, setType] = useState("password");

  const { data } = useGetQuery({
    url: "/roles",
    queryKey: ["roles"],
  });

  const handleToggle = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: user ? user.first_name : "",
      last_name: user ? user.last_name : "",
      email: user ? user.email : "",
      phone_number: user ? user.phone_number : "",
      password: "",
      role_id: user ? user.role_id : "",
    },
  });

  console.log({ queryClient });

  const { mutate } = useMutate(["register"]);
  const submit = (data) => {
    setIsSubmitting(true);
    mutate(
      {
        url: user ? "/users" : "/register",
        method: user ? "PATCH" : "POST",
        data: {
          ...(user && { id: user._id }),
          ...data,
        },
      },
      {
        async onSuccess(data) {
          console.log({ data });
          if (data?.message?.message?.includes("already exist")) {
            setIsSubmitting(false);
            toast.error(data.message.message);
          } else {
            await queryClient.setQueryData(["users"], (oldData) => {
              if (user) {
                return {
                  message: (oldData?.message ?? []).map((item) => {
                    if (item._id === user?._id) {
                      return data.message;
                    }
                    return item;
                  }),
                };
              } else {
                return {
                  message: [data?.message, ...(oldData?.message ?? [])],
                };
              }
            });
            setIsSubmitting(false);
            closeModal();
          }
        },
        onError(error) {
          console.log({ error });

          toast.error(error.response.data.message.message);
          setIsSubmitting(false);
        },
      }
    );
  };

  return (
    <div>
      <Spinner isSubmitting={isSubmitting} />

      <form onSubmit={handleSubmit(submit)}>
        <div className="w-[485px] text-zinc-900 text-2xl font-semibold font-kas leading-loose">
          Add User
        </div>
        <div className="w-[485px] text-neutral-500 text-sm font-normal font-kas leading-[21px]">
          These will only take you few minutes
        </div>

        <div className="mt-7  ">
          <div className="flex justify-between gap-3">
            <InputField
              name="first_name"
              label="First Name"
              placeholder="Enter Your First Name"
              errors={errors}
              register={register}
              errorMessage="Please Enter Your First Name"
              required
            />

            <InputField
              name="last_name"
              label="Last Name"
              placeholder="Enter Your Last Name"
              errors={errors}
              register={register}
              errorMessage="Please Enter Your Last Name"
              required
            />
          </div>

          <div className="mt-5">
            <InputField
              name="email"
              label="Email"
              placeholder="Enter Your Email"
              errors={errors}
              register={register}
              errorMessage="Please Enter Your Email"
            />
          </div>

          <div className="flex justify-between gap-4 mt-5">
            <InputField
              name="phone_number"
              label="Phone Number"
              placeholder="Enter Phone Number"
              errors={errors}
              register={register}
              errorMessage="Please Enter Phone Number"
              required
            />
          </div>
          {!user ? (
            <div className="flex items-center w-full outline-none border-gray-200 rounded-xl">
              <div className="mt-5 w-full">
                <InputField
                  type={type}
                  name="password"
                  label="Password"
                  placeholder="Create Password"
                  errors={errors}
                  register={register}
                  errorMessage="Please Create Password"
                  required
                />
              </div>

              <div onClick={handleToggle} className="cursor-pointer ml-2">
                {type === "password" ? (
                  <Icon
                    icon="fluent:eye-off-20-regular"
                    className="mr-1 h-6 w-6 mt-11"
                  />
                ) : (
                  <Icon
                    icon="fluent:eye-32-regular"
                    className="mr-1 h-6 w-6 mt-11"
                  />
                )}
              </div>
            </div>
          ) : null}

          <div className="mt-5">
            <div className="mb-2 block text-md font-sem text-zinc-800 text-sm font-medium font-kas leading-tight">
              Assign Role
            </div>
            <select
              name="role_id"
              className=" placeholder:text-slate-400 block bg-white w-full outline-none border border-gray-200 rounded-xl py-3 pl-4 pr-3 sm:text-sm "
              {...register("role_id", {
                required: {
                  value: true,
                  message: "Please Select A Role",
                },
              })}
            >
              <option>Select Role</option>
              {data?.map((value, index) => (
                <option key={value._id} value={value._id}>
                  {value.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="mt-6 flex justify-between gap-5">
          <button
            type="button"
            onClick={() => closeModal()}
            className="flex-1 rounded-xl font-kas text-sm border"
          >
            Cancel
          </button>
          <button className="flex-1 rounded-xl font-kas text-sm bg-primary text-white py-2">
            {user ? "Update" : "Add"} User
          </button>
        </div>
      </form>
    </div>
  );
}

export default UserForm;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

function SubMenu({ item, location }) {
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);

  const activeLink = location.pathname;

  useEffect(() => {
    if (
      activeLink === item.path ||
      (item.subNav &&
        item.subNav.some((subNavItem) =>
          activeLink.startsWith(subNavItem.path)
        ))
    ) {
      setSubnav(true);
    } else {
      setSubnav(false);
    }
  }, [activeLink, item.path, item.subNav]);

  return (
    <div className="text-zinc-600">
      <div to={item.path} className={`flex justify-between items-center mt-3 `}>
        <div
          className={`sidebarLink w-full space-y-4 flex font-bold font-kas justify-between items-center list-none h-12 font-second text-md mx-4 cursor-pointer rounded-lg  ${
            activeLink === item.path || subnav ? "bg-primary text-white" : ""
          }`}
          to={item.path}
          onClick={item.subNav && showSubnav}
        >
          <Link
            to={item.path}
            className={`w-full flex justify-between items-center p-3 rounded-lg`}
          >
            <div className="flex items-center">
              <div
                className={`mr-2 ${
                  activeLink === item.path || subnav ? "text-white" : ""
                }`}
              >
                {item.icon}
              </div>
              <div
                className={`font-ray SidebarLabel font-semibold text-sm  w-[8rem] truncate ${
                  activeLink === item.path || subnav ? "text-white" : ""
                }`}
              >
                {item.groupTitle}
              </div>
            </div>
            <div
              className={`${
                activeLink === item.path || subnav ? "text-primary" : ""
              }`}
            >
              {item.subNav &&
                item.subNav.length > 0 &&
                (subnav ? item.iconOpen : item.iconClosed)}
            </div>
          </Link>
        </div>
      </div>

      {subnav &&
        item.subNav &&
        item.subNav.length > 0 &&
        item.subNav.map(
          (subNavItem, index) =>
            subNavItem.condition && (
              <div
                className="font-second ml-[18%] text-sm space-y-2 mt-3"
                to={item.path}
                key={index}
              >
                <Link
                  to={subNavItem.path}
                  className={`Sidebarlabel flex items-center space-y-4 space-x-2 tracking-wider mb-3 font-kas font-semibold `}
                >
                  {" "}
                  <div
                    className={`${
                      activeLink === subNavItem.path
                        ? "text-zinc-600"
                        : "text-zinc-600"
                    } flex`}
                  >
                    {activeLink === subNavItem.path ? (
                      <Icon icon="ep:select" className="h-3 w-3 mr-2 mt-1" />
                    ) : (
                      subNavItem.icon
                    )}

                    {subNavItem.title}
                  </div>
                </Link>
              </div>
            )
        )}
    </div>
  );
}

export default SubMenu;

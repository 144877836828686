import { Icon } from "@iconify/react";
import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import toast from "react-hot-toast";
import UserForm from "../../components/forms/UserForm";
import { Avatar } from "../../components/shared/Avatar";
import Modal from "../../components/shared/Modal";
import Spinner from "../../components/shared/Spinner";
import Table from "../../components/shared/Table";
import useConfirm from "../../hooks/useConfirm";
import { useGetQuery } from "../../hooks/useGetQuery";
import { useMutate } from "../../hooks/useMutate";
import UserDetails from "./UserDetails";
import { useEffect } from "react";

function Users() {
  const queryClient = useQueryClient();
  const { ConfirmationDialog, confirm, setIsOpen } = useConfirm();
  const [user, setUser] = useState(null);
  const [OpenUsersForm, setOpenUsersForm] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { data, isLoading } = useGetQuery({
    url: "/users",
    queryKey: ["users"],
  });

  const handleEdit = (user) => {
    setUser(user);
    setOpenUsersForm(true);
  };

  const { mutate } = useMutate({ queryKey: ["delete-group"] });
  const handleDelete = async (user) => {
    const isConfirmed = await confirm({
      title: "Are You Sure?",
      message: `Are you sure you want to delete "${user?.first_name} ${user?.last_name}"?`,
    });

    if (isConfirmed) {
      const toastId = toast.loading(`Deleting ${user?.name} user...`);
      mutate(
        {
          url: `/user`,
          method: "DELETE",
          data: { id: user._id },
        },
        {
          onSuccess() {
            queryClient.setQueryData(["users"], (oldData) => {
              return {
                message: (oldData?.message || []).filter(
                  (item) => item._id !== user?._id
                ),
              };
            });
            toast.dismiss(toastId);
            toast.success(`${user?.name} deleted successfully`);
            setIsOpen(false);
          },
          onError(error) {
            console.log({ error });
            toast.dismiss(toastId);
            toast.error(error.response.data.message);
            setIsOpen(false);
          },
        }
      );
    }
  };

  const columns = [
    {
      id: "SN",
      header: "SN",
      accessorKey: "",
      cell: (info) => <span className="px-1">{info.row.index + 1}</span>,
    },
    {
      header: "Full Name",
      accessorFn: (row) => `${row.first_name} ${row.last_name}`,
      cell: (cell) => (
        <div className="flex items-center gap-2">
          {cell.row.original.profile_image ? (
            <img
              src={cell.row.original.profile_image}
              alt=""
              className="w-10 h-10 rounded-full"
            />
          ) : (
            <Avatar size={40} />
          )}
          <div>
            <h6>
              {cell.row.original.first_name} {cell.row.original.last_name}
            </h6>
            <p className="text-xs text-gray-600">{cell.row.original.email}</p>
          </div>
        </div>
      ),
    },
    {
      header: "Phone Number",
      accessorKey: "phone_number",
    },
    {
      header: "Role",
      accessorFn: (row) => row.role.name,
    },
    {
      header: "Details",
      cell: (info) => (
        <button
          onClick={() => {
            setUser(info.row.original);
            setOpenModal(true);
          }}
          className="border border-primary rounded-md font-kas text-xs text-primary py-1 px-3 hover:bg-primary hover:text-white duration-300"
        >
          Details
        </button>
      ),
    },
    {
      header: "Actions",
      cell: (info) => (
        <div className="w-[%] flex gap-3">
          <Icon
            onClick={() => handleEdit(info.row.original)}
            icon="iconamoon:edit-light"
            className="text-xl"
          />
          <Icon
            onClick={() => handleDelete(info.row.original)}
            icon="fluent:delete-48-regular"
            className="text-xl text-red-700"
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (!OpenUsersForm) {
      setUser(null);
    }
  }, [OpenUsersForm]);

  return (
    <div className="p-4 cursor-pointer">
      <Spinner isSubmitting={isLoading} />
      <div className="flex justify-between items-center">
        <div className="text-zinc-900 text-lg font-medium font-kas my-4">
          Users table
        </div>
        <div
          onClick={() => setOpenUsersForm(true)}
          className="flex justify-center items-center bg-[#008080] mr- py-3 rounded-lg px-3"
        >
          <div className="text-center text-white text-md font-medium font-new leading-normal flex">
            <Icon icon="iconoir:plus" className="text-white mr-1 h-6 w-6" />
            Add User
          </div>
        </div>
      </div>

      <Table data={data?.message ?? []} columns={columns} />
      <Modal openModal={OpenUsersForm} closeModal={setOpenUsersForm}>
        <UserForm user={user} closeModal={() => setOpenUsersForm(false)} />
      </Modal>

      <ConfirmationDialog />

      <Modal
        start
        width="w-full"
        openModal={openModal}
        closeModal={setOpenModal}
      >
        <UserDetails user={user} setUser={setUser} />
      </Modal>
    </div>
  );
}

export default Users;

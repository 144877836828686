import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useUser } from "./useUser";

export const useGetQuery = ({
  url,
  data = {},
  queryKey = [],
  method = "GET",
  enabled = true,
}) => {
  const user = useUser();
  return useQuery({
    queryKey: [...queryKey],
    queryFn: async () => {
      const res = await axios(`${process.env.REACT_APP_BASE_URL}${url}`, {
        method,
        data,
        headers: {
          Authorization: `Bearer ${user.auth_token}`,
          roleid: user.role._id,
        },
      });
      return res.data;
    },
    enabled,
  });
};

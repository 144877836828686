import React, { useEffect, useState } from "react";
import InputField from "../shared/InputField";
import { useForm } from "react-hook-form";
import { Icon } from "@iconify/react";
import toast from "react-hot-toast";
import { useMutate } from "../../hooks/useMutate";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { imageSchema } from "../../utils/image-schema";

const schema = (owner) =>
  z
    .object({
      first_name: z.string().min(1, "First name is required"),
      last_name: z.string().min(1, "Last name is required"),
      phone_number: z.string().length(10, "Please enter a valid phone number"),
      email: z.string().email(),
      profile_image: z.any(),
      ghana_card: imageSchema,
      ghana_card_id: z.string().min(1, "Ghana Card number is required"),
      ...(!owner && {
        password: z.string().min(6, "Password must be 6 characters long"),
      }),
    })
    .superRefine((val, ctx) => {
      if (!/^GHA-\d{9}-\d$/i.test(val.ghana_card_id)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Please enter a valid Ghana Card ID",
          path: ["ghana_card_id"],
        });
      }
    });

const AddBusinessOwnerForm = ({ owner, setView }) => {
  const [profileImage, setProfileImage] = useState(null);
  const [ghanaCardImage, setGhanaCardImage] = useState(null);
  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [ghanaCardImagePreview, setGhanaCardImagePreview] = useState(null);
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: owner?.first_name || "",
      last_name: owner?.last_name || "",
      phone_number: owner?.phone_number || "",
      email: owner?.email || "",
      profile_image: owner?.profile_image || "",
      ghana_card: owner?.ghana_card || "",
      ghana_card_id: owner?.ghana_card_id || "",
      password: "",
    },
    resolver: zodResolver(schema(owner)),
  });

  const { mutate } = useMutate(["add-business-owner"]);
  const submit = (data) => {
    const toastId = toast.loading("Adding Business Info...");
    const formData = new FormData();
    Object.entries(data).forEach((item) => {
      formData.append(...item);
    });

    mutate(
      {
        url: "/register/business/1",
        data: formData,
      },
      {
        onSuccess(data) {
          toast.dismiss(toastId);
          toast.success("Business registered successfully");
          setView("Business's Details");
        },
        onError(error) {
          toast.dismiss(toastId);
          toast.error(error.response.data.message);
        },
      }
    );
  };

  useEffect(() => {
    if (profileImage) {
      setValue("profile_image", profileImage);
      setProfileImagePreview(URL.createObjectURL(profileImage));
    }
  }, [profileImage, setValue]);

  useEffect(() => {
    if (ghanaCardImage) {
      setValue("ghana_card", ghanaCardImage);
      setGhanaCardImagePreview(URL.createObjectURL(ghanaCardImage));
    }
  }, [ghanaCardImage, setValue]);

  useEffect(() => {
    if (owner) {
      setGhanaCardImagePreview(owner.ghana_card);
      setProfileImagePreview(owner.profile_image);
    }
  }, [owner]);

  return (
    <div>
      <div className="mb-10">
        <h3 className="text-3xl font-bold text-gray-600">
          Business Owner Info
        </h3>
        <p className="text-slate-500">
          Please enter the information for the business owner
        </p>
      </div>

      <form onSubmit={handleSubmit(submit)}>
        <div className="form-row">
          <InputField
            label="First Name"
            name="first_name"
            placeholder="First Name"
            register={register}
            errors={errors}
            required
          />
          <InputField
            label="Last Name"
            name="last_name"
            placeholder="Last Name"
            register={register}
            errors={errors}
            required
          />
        </div>

        <div className="form-row">
          <InputField
            label="Phone Number"
            name="phone_number"
            placeholder="Phone Number"
            register={register}
            errors={errors}
            required
          />
          <InputField
            type="email"
            label="Email"
            name="email"
            placeholder="Email"
            register={register}
            errors={errors}
            required
          />
        </div>

        {!owner ? (
          <div className="form-row">
            <InputField
              type="password"
              label="Password"
              name="password"
              placeholder="Password"
              register={register}
              errors={errors}
              required
            />
            <InputField
              type="password"
              label="Confirm Password"
              name="confirm_password"
              placeholder="Confirm Password"
              register={register}
              errors={errors}
              required
            />
          </div>
        ) : null}

        <div className="form-row">
          <InputField
            label="Ghana Card ID"
            name="ghana_card_id"
            placeholder="Ghana Card ID"
            register={register}
            errors={errors}
            required
          />
        </div>
        <div className="form-row">
          <div className="flex-1">
            {profileImagePreview ? (
              <div className="flex gap-5 flex-wrap justify-center py-3">
                <div className="relative h-32 w-[200px] shrink-0 rounded-md bg-slate-500">
                  <div className="overflow-hidden">
                    <img
                      src={profileImagePreview}
                      style={{ objectFit: "cover" }}
                      alt=""
                      className="w-full h-32 rounded object-contain"
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <div className="w-full md:w-11/12 mx-auto">
              <label className="mb-2 block text-md font-sem text-zinc-800 text-sm font-medium font-kas leading-tight">
                Ghana Card
              </label>
              <label
                htmlFor="dropzone-file-1"
                className={`flex-1 w-full flex items-center justify-center  border-[1px] border-gray-200 border-dashed rounded-lg cursor-pointer p-2`}
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <div className="text-center text-sm">
                    <div className="ml-2 text-green-600 text-xs font-normal font-kas">
                      browse files
                    </div>
                  </div>
                  <p className=" dark:text-gray-400 text-neutral-500 text-xs font-normal font-kas">
                    or drag and drop your files
                  </p>
                </div>
                <input
                  id="dropzone-file-1"
                  type="file"
                  className="hidden"
                  onChange={(e) => setProfileImage(e.target.files[0])}
                />
              </label>
            </div>
          </div>
          <div className="flex-1">
            {ghanaCardImagePreview ? (
              <div className="flex gap-5 flex-wrap justify-center py-3">
                <div className="relative h-32 w-32 shrink-0 rounded-md bg-slate-500">
                  <div className="overflow-hidden">
                    <img
                      src={ghanaCardImagePreview}
                      style={{ objectFit: "cover" }}
                      alt=""
                      className="w-32 h-32 rounded object-cover"
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <div className="w-full md:w-11/12 mx-auto">
              <label className="mb-2 block text-md font-sem text-zinc-800 text-sm font-medium font-kas leading-tight">
                Profile Image
              </label>
              <label
                htmlFor="dropzone-file-1"
                className={`flex-1 w-full flex items-center justify-center  border-[1px] border-gray-200 border-dashed rounded-lg cursor-pointer p-2`}
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <div className="text-center text-sm">
                    <div className="ml-2 text-green-600 text-xs font-normal font-kas">
                      browse files
                    </div>
                  </div>
                  <p className=" dark:text-gray-400 text-neutral-500 text-xs font-normal font-kas">
                    or drag and drop your files
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                  onChange={(e) => setGhanaCardImage(e.target.files[0])}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-10">
          <button className="bg-primary text-white py-2 px-8 rounded-lg">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default AddBusinessOwnerForm;

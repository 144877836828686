import React from "react";

function InputField({
  name,
  register,
  errors,
  label = "",
  type = "text",
  required = false,
  errorMessage = "",
  placeholder = "",
  ...res
}) {

  return (
    <div className="flex-1">
      {label && (
        <label className="mb-2 block text-md font-sem text-zinc-800 text-sm font-medium font-kas leading-tight">
          {label}{" "}
          {!required && (
            <span className="text-slate-300 text-md">(Optional)</span>
          )}
        </label>
      )}
      <input
        type={type}
        placeholder={placeholder}
        className=" placeholder:text-slate-400 block bg-white w-full outline-none border border-gray-200 rounded-xl py-3 pl-4 pr-3 sm:text-sm "
        {...register(name, {
          ...(required && {
            required: {
              value: true,
              message: errorMessage,
            },
          }),
        })}
        {...res}
      />
      {errors[name] && (
        <span className="text-red-500 text-[12px]">{errors[name].message}</span>
      )}
    </div>
  );
}

export default InputField;

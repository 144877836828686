import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { useGetQuery } from "../../hooks/useGetQuery";
import { Link } from "react-router-dom";
import PaymentDetails from "../Payment/PaymentDetails";
import Modal from "../../components/shared/Modal";
import BarChart from "../../components/shared/BarChart";
import { sortData } from "../../utils/sortData";
import Spinner from "../../components/shared/Spinner";

function Dashboard() {
  const [payment, setPayment] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const { data: members, isLoading: loadingMembers } = useGetQuery({
    url: "/members",
    queryKey: ["members"],
  });

  const { data: groups, isLoading: loadingGroups } = useGetQuery({
    url: "/groups",
    queryKey: ["groups"],
  });
  const { data: welfares, isLoading: loadingWelfares } = useGetQuery({
    url: "/welfares",
    queryKey: ["welfares"],
  });
  const { data: business, isLoading: loadingBusiness } = useGetQuery({
    url: "/businesses",
    queryKey: ["businesses"],
  });
  const { data: users, isLoading: loadingUsers } = useGetQuery({
    url: "/users",
    queryKey: ["users"],
  });
  const { data: payments, isLoading: loadingPayments } = useGetQuery({
    url: "/payments",
    queryKey: ["payments"],
    method: "POST",
  });

  const totalAmount = payments?.message?.reduce(
    (total, payment) => total + parseFloat(payment.amount),
    0
  );

  const totalWelfare = welfares?.message?.reduce(
    (total, welfare) => total + parseFloat(welfare.welfare_amount),
    0
  );

  const roundedTotalPayment =
    totalAmount !== undefined ? totalAmount.toFixed(2) : "0.00";

  const roundedTotalWelfare =
    totalWelfare !== undefined ? totalWelfare.toFixed(2) : "0.00";

  return (
    <div className="p-4 cursor-pointer">
      <Spinner
        isSubmitting={[
          loadingMembers,
          loadingBusiness,
          loadingGroups,
          loadingWelfares,
          loadingUsers,
          loadingPayments,
        ].some((item) => item)}
      />
      <div className="grid grid-cols-3 gap-4">
        <div className="bg-white rounded-xl p-5 shadow-sm">
          <div>
            <div className="h-10 w-10 bg-green-300 flex justify-center items-center rounded-md bg-opacity-30">
              <Icon icon="fluent:data-pie-24-regular" className="h-6 w-6 " />
            </div>
            <div className="flex justify-between mt-10">
              <div>
                <div className="text-neutral-400 text-sm font-normal font-kas leading-snug">
                  Total Dues
                </div>
                <div className="text-zinc-900 text-xl font-medium font-kas leading-relaxed">
                  GHS {roundedTotalPayment}
                </div>
              </div>

              <div>
                <div className="text-neutral-400 text-sm font-normal font-kas leading-snug">
                  All Payments
                </div>
                <div className="text-zinc-900 text-xl font-medium font-kas leading-relaxed">
                  {payments?.message?.length}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl p-5 shadow-sm">
          <div>
            <div className="h-10 w-10 bg-red-200 flex justify-center items-center rounded-md bg-opacity-30">
              <Icon
                icon="solar:users-group-rounded-linear"
                className="h-6 w-6 "
              />
            </div>
            <div className="flex justify-between mt-10">
              <div>
                <div className="text-neutral-400 text-sm font-normal font-kas leading-snug">
                  All Groups
                </div>
                <div className="text-zinc-900 text-xl font-medium font-kas leading-relaxed">
                  {groups?.message?.length}
                </div>
              </div>

              <div>
                <div className="text-neutral-400 text-sm font-normal font-kas leading-snug">
                  All Users
                </div>
                <div className="text-zinc-900 text-xl font-medium font-kas leading-relaxed">
                  {users?.message?.length}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white  rounded-xl p-5 shadow-sm">
          <div>
            <div className="h-10 w-10 bg-red-200 flex justify-center items-center rounded-md bg-opacity-30">
              <Icon icon="solar:bag-4-linear" className="h-6 w-6 " />
            </div>
            <div className="flex justify-between mt-10">
              <div>
                <div className="text-neutral-400 text-sm font-normal font-kas leading-snug">
                  All Businesses
                </div>
                <div className="text-zinc-900 text-xl font-medium font-kas leading-relaxed">
                  {business?.message?.length}
                </div>
              </div>

              <div>
                <div className="text-neutral-400 text-sm font-normal font-kas leading-snug">
                  Active Business
                </div>
                <div className="text-zinc-900 text-xl font-medium font-kas leading-relaxed">
                  0
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mt-5">
        <div className="bg-white rounded-xl shadow-sm px-5 py-3">
          <BarChart input={sortData(payments?.message)} />
        </div>

        <div className="space-y-3">
          <div className="bg-[#008080] rounded-xl p-5 shadow-sm">
            <div>
              <div className="h-10 w-10 bg-white flex justify-center items-center rounded-md bg-opacity-30">
                <Icon
                  icon="solar:users-group-rounded-linear"
                  className="h-6 w-6 text-white "
                />
              </div>
              <div className="flex justify-between mt-10">
                <div>
                  <div className="text-white text-lg font-normal font-kas leading-snug">
                    All Members
                  </div>
                  <div className="text-white text-xl font-medium font-kas leading-relaxed">
                    {members?.message?.length}
                  </div>
                </div>
                <div>
                  <div className="text-white text-lg font-normal font-kas leading-snug">
                    Active Members
                  </div>
                  <div className="text-white text-xl font-medium font-kas leading-relaxed">
                    0
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>

          <div className="bg-white  rounded-xl p-5 shadow-sm">
            <div>
              <div className="h-10 w-10 bg-red-200 flex justify-center items-center rounded-md bg-opacity-30">
                <Icon icon="solar:hand-money-linear" className="h-6 w-6 " />
              </div>
              <div className="flex justify-between mt-10">
                <div>
                  <div className="text-neutral-400 text-sm font-normal font-kas leading-snug">
                    All Welfare
                  </div>
                  <div className="text-zinc-900 text-xl font-medium font-kas leading-relaxed">
                    {welfares?.message?.length}
                  </div>
                </div>

                <div className="mr-48">
                  <div className="text-neutral-400 text-sm font-normal font-kas leading-snug">
                    Total Welfare
                  </div>
                  <div className="text-zinc-900 text-xl font-medium font-kas leading-relaxed">
                    GHS {roundedTotalWelfare}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white pt-6 pb-4 mt-4 rounded-xl px-6">
        <div className="flex items-center justify-between">
          <div className="text-zinc-900 text-lg font-medium font-kas leading-relaxed">
            <Link to="/payments">Payment Summary</Link>
          </div>
        </div>

        <div className=" mt-8 ">
          <div className="flex text-neutral-900 text-[15px] font-normal p-3 font-kas leading-tight tracking-tight">
            <div className="w-[10%] text-slate-400"> SN</div>
            <div className="w-[25%] mr-5 text-slate-400"> Transaction ID</div>
            <div className="w-[30%] text-slate-400">Business Name</div>
            <div className="w-[20%] mr-8 text-slate-400">Purpose</div>
            <div className="w-[10%] mr-10 text-slate-400">Amount</div>
            <div className="w-[20%] mr-8 text-slate-400">Date Paid</div>

            <div className="w-[%] text-slate-400">Action</div>
          </div>

          <div className="border-b-[0.8px] border-slate-300 mt-1"></div>

          {payments &&
            payments?.message?.slice(0, 6).map((payment, index) => {
              return (
                <div
                  key={index}
                  className={`flex items-center text-zinc-800 text-sm font-normal font-kas leading-tight tracking-tight py-3 pl-3 border-b-[0.8px] border-slate-300 hover:bg-slate-100 ${
                    index % 2 === 1 ? "bg-primary/5 rounded-" : ""
                  }`}
                >
                  <div className="w-[10%]">{index + 1}</div>

                  <div className="w-[25%] flex items-center ">
                    {payment.transactionId}
                  </div>
                  <div className="w-[25%]">{payment.payer.business_name}</div>

                  <div className="w-[20%] truncate mr-5">{payment.purpose}</div>
                  <div className="w-[10%] truncate mr-5">
                    {payment.currency} {payment.amount}
                  </div>
                  <div className="w-[20%] truncate mr-5">
                    {payment.date_created}
                  </div>

                  <div
                    onClick={() => {
                      setPayment(payment);
                      setOpenModal(true);
                    }}
                    className="border border-primary rounded-md text-xs text-primary py-1 px-3 hover:bg-primary hover:text-white duration-300"
                  >
                    <button>Details</button>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <Modal
        start
        width="w-full"
        openModal={openModal}
        closeModal={setOpenModal}
      >
        <PaymentDetails payment={payment} setPayment={setPayment} />
      </Modal>
    </div>
  );
}

export default Dashboard;

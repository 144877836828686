import { Icon } from "@iconify/react";
import React from "react";

export const SidebarData = [
  {
    groupTitle: "Dashboard",
    path: "/",
    icon: <Icon icon="radix-icons:dashboard" className="h-6 w-6" />,
  },
  {
    groupTitle: "Payment",
    path: "/payments",
    icon: <Icon icon="solar:wallet-outline" className="h-6 w-6" />,
  },
  {
    groupTitle: "Businesses",
    path: "/business",
    icon: <Icon icon="solar:bag-4-linear" className="h-6 w-6 " />,
  },
  {
    groupTitle: "Welfare",
    path: "/welfares",
    icon: <Icon icon="solar:hand-money-linear" className="h-6 w-6 " />,
  },
  // {
  //   groupTitle: <Link to="/announcements">Announcement,
  //   path: "/announcements",
  //   icon: <Icon icon="grommet-icons:announce" className="h-6 w-6 " />,
  // },
  {
    groupTitle: "Members",
    path: "/members",
    icon: <Icon icon="solar:users-group-rounded-linear" className="h-6 w-6 " />,
  },
  {
    groupTitle: "Users",
    path: "/users",
    icon: <Icon icon="ph:users-three" className="h-6 w-6 " />,
  },
  {
    groupTitle: "Roles",
    path: "/roles",
    icon: <Icon icon="solar:list-up-bold" className="h-6 w-6 " />,
  },
  {
    groupTitle: "Groups",
    path: "/groups",
    icon: <Icon icon="iconamoon:category-light" className="h-6 w-6 " />,
  },
  {
    groupTitle: "Settings",
    path: "/settings",
    icon: <Icon icon="fluent:settings-28-regular" className="h-6 w-6 " />,
  },
];

import React from "react";
import { useForm } from "react-hook-form";
import InputField from "../shared/InputField";

function WelfareForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submit = (data) => {};

  return (
    <div>
      <form onSubmit={handleSubmit(submit)}>
        <div className="w-[485px] text-zinc-900 text-2xl font-semibold font-kas leading-loose">
          Add Welfare
        </div>
        <div className="w-[485px] text-neutral-500 text-sm font-normal font-kas leading-[21px]">
          These will only take you few minutes
        </div>

        <div className="mt-7  ">
          <InputField
            name="Contribution Name"
            label="Contribution Name"
            placeholder="Phnes shop bill"
            errors={errors}
            register={register}
            required
          />

          <div className="flex justify-between gap-4 mt-5">
            <InputField
              name="Amount"
              label="Amount"
              placeholder="GH60"
              errors={errors}
              register={register}
              required
            />
            <InputField
              name="Payment Method"
              label="Payment Method"
              placeholder="**** 1937 "
              errors={errors}
              register={register}
              required
            />
          </div>
        </div>

        <div className="mt-6 flex justify-between">
          <div className="flex justify-center items-center mr-5 rounded-xl px-3 border w-full">
            <div className="text-neutral-900 text-sm font-bold font-kas capitalize leading-10">
              Cancel
            </div>
          </div>

          <div className="flex justify-center items-center bg-[#008080] rounded-xl px-10 w-full">
            <div className="text-center text-white text-sm font-medium font-kas font-new leading-normal flex ">
              Save Now
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default WelfareForm;
